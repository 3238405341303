import { CommonModule } from '@angular/common';
import { Component, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SharedConst } from '@app/shared/const/shared-const';
import { ChartConfiguration, ChartData, MousePosition, ChartRawData, LineConfig } from '@app/shared/models/line-chart.model';
import * as d3 from 'd3';
 
@Component({
  selector: 'app-common-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss'],
  standalone: true,
  imports: [CommonModule]
})

export class LineGraphComponent implements OnChanges {
  sharedConst = SharedConst;

  @Input() lineChartData: ChartData;
  @Input() chartMetaData: ChartConfiguration;
  @Input() lineConfig: LineConfig[];

  @ViewChild('lineChartContainer', { static: false })
  private lineChartContainer: ElementRef;

  constructor() {
    
  }

  public ngOnChanges(): void {
    if (this.lineChartData && this.lineChartData.categories) {
      this.lineChartData.categories.push(null);
      this.lineChartData.categories = this.lineChartData.categories.filter(Boolean);
      this.initChart();
    }
  }

  private initChart(): void {
  
    d3.select(this.lineChartContainer.nativeElement).selectAll('svg').remove();
    
    const width = this.lineChartContainer.nativeElement.offsetWidth - this.chartMetaData.margin.left - this.chartMetaData.margin.right;
    const height = this.lineChartContainer.nativeElement.offsetHeight - this.chartMetaData.margin.top - this.chartMetaData.margin.bottom;

    
    const x = d3.scaleBand()
      .domain(this.lineChartData.categories)
      .rangeRound([0, width]) 
  .paddingInner(0) 
  .paddingOuter(0);

    
    const y = d3.scaleLinear()
      .range([height, 0]);

    
    const xAxis = d3.axisBottom(x)
    .tickSize(0)
      .tickFormat((d, i) => this.lineChartData.categories[i]);

    
    const yAxis = d3.axisLeft(y)
    .tickSize(0)
    .tickFormat((d) => {
      if (this.chartMetaData.yAxis === 'percentage') {
        return Math.round(d as number) + '%'; 
      } else if (this.chartMetaData.yAxis === 'currency') {
        return '$' + Math.round(d as number); 
      } else {
        return Math.round(d as number).toString(); 
      }
    });

   
    const line = d3.line<any>()
      .defined(d => d.count !== null)
      .x((d) => x(d.month) + x.bandwidth() / 2)
      .y((d) => y(d.count))
      .curve(d3.curveMonotoneX);

    
    const svg = d3.select(this.lineChartContainer.nativeElement)
      .append('svg')
      .attr('width', width + this.chartMetaData.margin.left + this.chartMetaData.margin.right)
      .attr('height', height + this.chartMetaData.margin.top + this.chartMetaData.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.chartMetaData.margin.left},${this.chartMetaData.margin.top})`);

    
    const graphLines = this.lineChartData.dataSeriesCollection.map((d) => {
      const category = d.legend;
      const lineDetails = this.lineConfig.filter(item => item.name === d.legend);
      let i = 0;
      const dataPoints = d.dataPoints.map((dataPiont) => {
        return {
          month: this.lineChartData.categories[i++],
          count: dataPiont.value,
          tooltipData: dataPiont.toolTipInfo,
          legend: category
        };
      });
      return {
        name: category,
        values: dataPoints,
        color: lineDetails[0].color,
        lineType: lineDetails[0].lineType
      };
    });

    
    if (this.chartMetaData.yAxis === 'percentage') {
      y.domain([0, 100]);
    } else {
      y.domain([
        d3.min(graphLines, (c) => d3.min(c.values, (v) => v.count)),
        d3.max(graphLines, (c) => d3.max(c.values, (v) => v.count))
      ]);
    }

    
    svg.append('g')
      .attr('class', 'x axis xAxis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .select('path')
      .style('stroke', '#d0cfcd')
      .style('fill', 'none')
      .style('stroke-width', 1)
      .style('shape-rendering', 'crispEdges');

    
    svg.append('g')
    
    .attr('class', 'y axis yAxis')
    .attr('transform', `translate(0, 0)`) 
    .call(yAxis)
    .select('path')
    .style('stroke', '#d0cfcd') 
    .style('fill', 'none')
    .style('stroke-width', 1) 
    .style('shape-rendering', 'crispEdges')
    .style('stroke-dasharray', 'none');
  

    
    if (this.chartMetaData.gridLines.xAxis) {
      svg.selectAll('g.xAxis g.tick')
        .append('line')
        .attr('class', 'gridline x')
        .attr('x1', 0)
        .attr('y1', -height)
        .attr('x2', 0)
        .attr('y2', 0)
        .attr('stroke-width', 1)
        .style('opacity', '1')
        .style('stroke', '#d0cfcd')
        .style('stroke-dasharray', '2');
    }

    
    if (this.chartMetaData.gridLines.yAxis) {
      svg.selectAll('g.yAxis g.tick')
        .append('line')
        .attr('class', 'gridline y')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', width)
        .attr('y2', 0)
        .attr('stroke-width', 1)
        .style('opacity', '1')
        .style('stroke', '#d0cfcd')
        .style('stroke-dasharray', '2');
    }

   
    svg.selectAll('g.xAxis g.tick text')
      .style('text-anchor', 'end')
      .style('font-size', '10px')
      .style('font-family', 'Noto Sans Regular')
      .attr('transform', `translate(${-10},0) rotate(-45)`);

   
    svg.selectAll('g.yAxis g.tick text')
      .style('font-size', '8px')
      .style('font-family', 'Noto Sans Regular')
      .style('text-transform', 'uppercase');

    
    const graphLine = svg.selectAll('.chart-line')
      .data(graphLines)
      .enter()
      .append('g')
      .attr('class', 'chart-line');

    graphLine.append('path')
      .attr('class', (d) => d.lineType === this.sharedConst.LINE_TYPE.DASHED ? 'dashed-line' : 'line')
      .attr('d', (d) => line(d.values))
      .style('stroke', (d) => this.resolveLineColor(d))
      .style('stroke-width', '1px')
      .style('stroke-dasharray', (d) => d.lineType === this.sharedConst.LINE_TYPE.DASHED ? 5 : 0)
      .style('fill', 'none');

    
    this.appendCircle(graphLine, x, y, width);
  }

  private appendCircle(graphLine: any, x: d3.ScaleBand<string>, y: d3.ScaleLinear<number, number>, width: number): void {
    graphLine.append('g').selectAll('circle')
      .data((d) => d.values)
      .enter()
      .append('circle')
      .attr('r', (d) => d.count === null ? 0 : 4)
      .attr('cx', (d) => x(d.month) + x.bandwidth() / 2)
      .attr('cy', (d) => y(d.count))
      .attr('fill', '#FFF')
      .attr('stroke', (d) => this.resolveLineColor(d))
      .on('mouseover', (event, d) => {
        const div = d3.select('body').append('div')
          .attr('class', 'tooltip-chart-line')
          .style('opacity', 0);
        div.transition()
          .duration(200)
          .style('opacity', .9);
        div.html(this.getTooltipContent(d))
          .style('left', () => {
            const pos = this.positionTooltip({
              x: event.pageX,
              y: event.pageY
            });
            return (pos.left + 10) + 'px';
          })
          .style('top', (event.pageY - 28) + 'px')
          .style('z-index', '10001');
      })
      .on('mouseout', () => {
        const tooltip = document.getElementsByClassName('tooltip-chart-line');
        while (tooltip[0]) {
          tooltip[0].parentNode.removeChild(tooltip[0]);
        }
        const div = d3.select('.tooltip-chart-line');
        div.transition()
          .duration(500)
          .style('opacity', 0);
      });
  }

  private positionTooltip(mouse: {x: number, y: number}): {top: number, left: number} {
    const box = document.querySelector('.graph-table');
    if (box) {
      const style = getComputedStyle(box);
      const toolwidth = parseInt(style.width);
      
      if (window.innerWidth - (mouse.x + toolwidth) < 100) { 
        mouse.x = mouse.x - toolwidth - 30;
      }
      return {
        top: mouse.y,
        left: mouse.x
      };
    }
    return { top: mouse.y, left: mouse.x }; 
  }

  private getTooltipContent(d: any): string {
    let tooltipContent = '<table class="graph-table" style="width: 150px; height: 70px;">';
    if (d.tooltipData) {
      d.tooltipData.forEach(element => {
        tooltipContent += `<tr><td class="title">${element.label}:</td><td class="data-value"> ${element.value} </td></tr>`;
      });
    }
    tooltipContent += '</table>';
    return tooltipContent;
  }

  private resolveLineColor(data: any): string {
    if (data.color) {
      return data.color;
    } else {
      const lineDetails = this.lineConfig.filter(item => item.name === data.legend);
      return lineDetails[0].color;
    }
  }
}
 