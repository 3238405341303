import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ChartDataItem, ChartConfig, BarConfig, MousePosition } from '@app/shared/models/grouped-bar-chart.model';

@Component({
  selector: 'app-shared-grouped-bar-chart',
  templateUrl: './grouped-bar-chart.component.html',
  styleUrls: ['./grouped-bar-chart.component.scss'],
  standalone: true
})
export class GroupedBarChartComponent implements OnChanges {
  @Input() boxesOrderedReplenishedBarData: ChartDataItem[] = [];
  @Input() barConfig: BarConfig = {
    bar1: '',
    bar2: ''
  };
  @Input() chartMetaData: ChartConfig = {
    margin: { top: 20, right: 20, bottom: 50, left: 50 }
  };

  @ViewChild('chartContainer', { static: false }) 
  private chartContainer!: ElementRef;

  ngOnChanges(): void {
    if (this.boxesOrderedReplenishedBarData?.length > 0) {
      setTimeout(() => {
        this.initChart(
          this.boxesOrderedReplenishedBarData, 
          this.barConfig, 
          this.chartMetaData
        );
      }, 100);
    }
  }

  private initChart(data: ChartDataItem[], colorConfig: BarConfig, chartMetaData: ChartConfig): void {
    // Clear any existing chart
    d3.select(this.chartContainer.nativeElement).html('');

    const container = this.chartContainer.nativeElement;
    const width = container.offsetWidth - chartMetaData.margin.left - chartMetaData.margin.right;
    const height = container.offsetHeight - chartMetaData.margin.bottom - chartMetaData.margin.top;

    // Create scales
    const x0 = d3.scaleBand()
      .domain(data.map(d => d.category))
      .rangeRound([0, width])
      .paddingInner(0.1);

    const x1 = d3.scaleBand()
      .domain(data[0].barChartData.map(d => d.label))
      .rangeRound([0, x0.bandwidth()])
      .padding(0.05);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, category => 
        d3.max(category.barChartData, d => d.value) as unknown as number
      ) as number + 1])
      .nice()
      .range([height, 0]);

    // Create SVG
    const svg = d3.select(this.chartContainer.nativeElement)
      .append('svg')
      .attr('width', width + chartMetaData.margin.left + chartMetaData.margin.right)
      .attr('height', height + chartMetaData.margin.bottom + chartMetaData.margin.top)
      .append('g')
      .attr('transform', `translate(${chartMetaData.margin.left},${chartMetaData.margin.top})`);

    // X Axis
    const xAxis = d3.axisBottom(x0)
      .tickSize(0);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .style('font-size', '10px')
      .style('font-family', 'Noto Sans Regular')
      .attr('transform', 'rotate(-45)');

    // Y Axis
    const yAxis = d3.axisLeft(y)
      .ticks(4)
      .tickSizeInner(0);

    const yAxisG = svg.append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .call(g => g.select('.domain').remove());
      

    // Add gridlines
    yAxisG.selectAll('.tick line')
      .clone()
      .attr('x2', width)
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '5, 5')
      .style('opacity', '1')
      .style('stroke', '#d0cfcd');

    // Y-axis label
    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - chartMetaData.margin.left)
      .attr('x', 0 - (height / 2))
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .style('font-size', '16px')
      .style('fill', '#63666A')
      

    // Create bars
    const groups = svg.selectAll('.group')
      .data(data)
      .enter().append('g')
      .attr('class', 'group')
      .attr('transform', d => `translate(${x0(d.category)},0)`);

    groups.selectAll('rect')
      .data(d => d.barChartData)
      .enter().append('rect')
      .attr('x', d => x1(d.label) || 0)
      .attr('y', height)
      .attr('width', x1.bandwidth())
      .attr('height', 0)
      .style('fill', d => colorConfig[d.label] || 'steelblue')
      .on('mouseover', (event, d) => this.showTooltip(event, d))
      .on('mouseout', () => this.hideTooltip())
      .transition()
      .delay((d, i) => i * 100)
      .duration(1000)
      .attr('y', d => y(d.value))
      .attr('height', d => height - y(d.value));
  }

  private showTooltip(event: MouseEvent, d: any): void {
    const div = d3.select('body').append('div')
      .attr('class', 'tooltip-chart-line')
      .style('opacity', 0);

    div.transition()
      .duration(200)
      .style('opacity', 0.9);

    let tooltipContent = '<table class="graph-table" style="width: 140px; height: 30px;">';
    if (d.toolTipInfo) {
      d.toolTipInfo.forEach((element: any) => {
        tooltipContent += `<tr><td class="title">${element.label}:</td><td class="data-value"> ${element.value} </td></tr>`;
      });
    }
    tooltipContent += '</table>';

    const tooltipPos = this.positionTooltip(event);

    div.html(tooltipContent)
      .style('left', `${tooltipPos.left + 10}px`)
      .style('top', `${tooltipPos.top - 28}px`)
      .style('z-index', '10001');
  }

  private hideTooltip(): void {
    d3.selectAll('.tooltip-chart-line').remove();
  }

  private positionTooltip(event: MouseEvent): MousePosition {
    const box = document.querySelector('.graph-table');
    let left = event.pageX;
    let top = event.pageY;
  
    if (box) {
      const toolWidth = box.getBoundingClientRect().width;
      // Adjust tooltip position if it would go off-screen
      if (window.innerWidth - (event.pageX + toolWidth) < 100) {
        left = event.pageX - toolWidth - 30;
      }
    }
  
    return { 
      top, 
      left, 
      x: event.pageX,  // Add x and y from the event
      y: event.pageY 
    };
  }
  
}
function tickSizeInner(arg0: number) {
  throw new Error('Function not implemented.');
}

