import { CanActivateFn } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreService } from '../core/core.service';
import { StorageService } from '../core/storage.service';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DomainGuard implements CanActivate, CanActivateChild {
    unAuthenticatedPages = ['login', 'request-access', 'home', 'forgot-password', 'reset-password',
        'reset-success', 'complete-registration', 'complete-registration-topics', 'complete-registration-notifications',
        'complete-registration-login', 'request-access', 'reset-password', 'user-setup', 'password-expired'];
    commonPages= ['search-results', 'privacy-policy', 'unauthorized', 'customer-support', 'legal-notice', 'california-compliance','pricing-disclosure'];
    opsPages = ['ops'];
    redirectTo: string = '/#/ops';

    constructor(
        private router: Router,
        private coreService: CoreService,
        private storageService: StorageService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise((resolve) => {
            let hostName = this.document.location.hostname;
            let hostNameArray: string[] = hostName.split(/(\/)|(\.)/)
                .filter(o => {
                    // if (o) {
                    //     return true;
                    // }
                    return !!o;
                });
            if (hostNameArray[0] === 'aitasolution' && state.url === '/external') {
                window.location.href = this.redirectTo;
            }else if((hostNameArray[0] === 'aitasolution' && state.url === '/') || (hostNameArray[0] === 'aitasolution' && state.url === '')){
               window.location.href = this.redirectTo;
            }else if((hostNameArray[0] === 'dev' && state.url === '/') || (hostNameArray[0] === 'dev' && state.url === '') || (hostNameArray[0] === 'test' && state.url === '/') || (hostNameArray[0] === 'test' && state.url === '') || (hostNameArray[0] === 'uat' && state.url === '/') || (hostNameArray[0] === 'uat' && state.url === '')){
                window.location.href = this.redirectTo;
             }

            if (hostName !== 'localhost') {
                hostName = hostName.substring(0, hostName.lastIndexOf('.'));
                this.storageService.setItem('hostName', hostName);
            } else {
                hostName = 'aitadevapi.jnj';
                let stringWithoutPrefix = environment.baseUrl.replace(/^https?:\/\//i, '');
                let finalString = stringWithoutPrefix.replace(/\.com\/aita-/i, '');
                this.storageService.setItem('hostName', finalString);
            }

            
            this.storageService.removeItem('institutionId');
            this.storageService.removeItem('facilityId');
            resolve(true);
        });
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }
}


// export const DomainGuard: CanActivateFn = (route, state) => {
//   return true;
// };
