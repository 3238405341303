import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ClinicalService } from '../clinical.service';
import { Router } from '@angular/router';
import { DataCollectionInfo, TileDataApiResponse, TileFilterInfo } from 'src/app/core/dashboard/models/clinical-dashboard-model.model';
import { Subscription, timer } from 'rxjs';
import { AdamConf } from '@app/app.config';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';
import { StackedBarChartComponent } from '../tile-graphs/stacked-bar-chart/stacked-bar-chart.component';

import { TileMagnifierComponent } from '../tile-magnifier/tile-magnifier.component';

interface ChartDataItem {
  category: string;
  bar1: number;
  bar1ToolTipInfo: any;
  bar2: number;
  bar2ToolTipInfo: any;
  bar3: number;
  bar3ToolTipInfo: any;
  line: number;
  lineToolTipInfo: any;
}

@Component({
  selector: 'app-products-usage-returns-tile',
  standalone: true,
  imports: [StackedBarChartComponent, TileMagnifierComponent],
  templateUrl: './products-usage-returns-tile.component.html',
  styleUrl: './products-usage-returns-tile.component.scss'
})
export class ProductsUsageReturnsTileComponent implements OnInit, OnDestroy {
  public isDisplayFilter = false;
  public filterConfig: any;
  public mtd: number;
  public ytd: number;
  public chartData: ChartDataItem[];
  public bar1Items: any;
  public bar2Items: any;
  public bar3Items: any;
  public lineItems: any;
  public chartMetaData: any;
  public adamLabels = AdamConf;
  private productUsageReturnsParams: TileFilterInfo;
  private filterSubscription$: Subscription;
  private dataSubscription$: Subscription;
  private ticker$: Subscription;
  @Input() showMagnifiedViewPopupData: any;
  @Output() showMagnifiedPopup = new EventEmitter<string[]>();

  constructor(
    private readonly clinicalService: ClinicalService,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService
  ) { }

  public ngOnInit(): void {
    this.chartData = [];
    this.bar1Items = [];
    this.bar2Items = [];
    this.bar3Items = [];
    this.lineItems = [];
    this.chartMetaData = {
      chartLabel: this.adamLabels.clinical_module.productUsageReturnsLabel,
      margin: {
        top: 20,
        right: 30,
        bottom: 50,
        left: 40
      }
    };
    this.loadProductUsageTileData();
    this.setAutoRefresh();
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Product Usage & Returns tile data');
        this.loadProductUsageTileData();
      });
    }
  }

  private loadProductUsageTileData(): void {
    this.resetProductUsageTileSubscriptions();
    this.filterSubscription$ = this.clinicalService.productUsageAndReturnsFilter$.subscribe((data) => {
      if (data) {
        this.productUsageReturnsParams = {
          tileName: 'Product Usage & Returns',
          tileFilters: data
        };
        this.getDataByFilters();
      }
    });
  }

  private getDataByFilters(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    this.dataSubscription$ = this.clinicalService.getClinicalTileData(this.productUsageReturnsParams).subscribe((resp) => {
      if (resp.responseCode === '2001') {
        resp.responseData.calloutData.forEach(dataItem => {
          if (dataItem.label === 'MTD') {
            this.mtd = dataItem.value;
          } else if (dataItem.label === 'YTD') {
            this.ytd = dataItem.value;
          }
        });
        if (resp.responseData.dataSeriesCollection) {
          this.lineItems = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.productUsageReturnsLineLabel);
          this.bar1Items = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.productUsageReturnsBar1Label);
          this.bar2Items = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.productUsageReturnsBar2Label);
          this.bar3Items = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.productUsageReturnsBar3Label);
        }
       
        this.setChartData(resp);
      } else {
        this.mtd = null;
        this.ytd = null;
      }
    });
  }

  private setChartData(resp: TileDataApiResponse): void {
    

    if (resp.responseData.categories && resp.responseData.categories.length > 0 &&
      this.bar1Items.length > 0 && this.bar2Items.length > 0 && this.lineItems.length > 0 && this.bar3Items.length > 0) {
      
      let tempArr = [];
      for (let i = 0; i < resp.responseData.categories.length; i++) {
        const tempChartItem = {
          category: resp.responseData.categories[i],
          bar1: this.bar1Items[i].value,
          bar1ToolTipInfo: this.bar1Items[i].toolTipInfo,
          bar2: this.bar2Items[i].value,
          bar2ToolTipInfo: this.bar2Items[i].toolTipInfo,
          bar3: this.bar3Items[i].value,
          bar3ToolTipInfo: this.bar3Items[i].toolTipInfo,
          line: this.lineItems[i].value,
          lineToolTipInfo: this.lineItems[i].toolTipInfo,
        };
        
        tempArr.push(tempChartItem);
      }
      this.chartData = tempArr;
      
    } else {
      console.warn('Data is missing or incomplete:', {
        categories: resp.responseData.categories,
        bar1Items: this.bar1Items,
        bar2Items: this.bar2Items,
        lineItems: this.lineItems,
        bar3Items: this.bar3Items
      });
    }
  }

  private getDataToDrawGraph(data: any, axes: string): DataCollectionInfo[] {
    let dataItems = [];
    const item0 = 0;
    const item1 = 1;
    const item2 = 2;
    const item3 = 3;
    const item4 = 4;
    switch (axes) {
      case data[item0].legend: {
        dataItems = data[item0].dataPoints;
        break;
      }
      case data[item1].legend: {
        dataItems = data[item1].dataPoints;
        break;
      }
      case data[item2].legend: {
        dataItems = data[item2].dataPoints;
        break;
      }
      case data[item3].legend: {
        dataItems = data[item3].dataPoints;
        break;
      }
      case data[item4].legend: {
        dataItems = data[item4].dataPoints;
        break;
      }
    }
    return dataItems;
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile =
      (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? this.adamLabels.clinical_module.productUsageReturnsLabel : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

  public displayFilter(): void {
    this.isDisplayFilter = true;
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      tile: 'Product Usage & Returns'
    };
    this.clinicalService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
  }

  public navigateToProductDetails(): void {
    this.router.navigate(['/insights/inventory/overview', 'all']);
  }

  public ngOnDestroy(): void {
    this.resetProductUsageTileSubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private resetProductUsageTileSubscriptions(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.filterSubscription$) {
      this.filterSubscription$.unsubscribe();
    }
  }
}
