<div class="preference-card-accuracy">
  <div class="header">
    <span class="card-heading">Preference Card Accuracy</span>
    <button class="btn btn-close-white button-info" (click)="navigateToPreferenceCardOverview()">
      <em class="material-icons">info_outline</em>
    </button>
    <div class="filter-expand">
      <div class="filter-container" (click)="displayFilter()">
        <p class="filter-text">Filters <em class="material-icons filter-icon">filter_list</em></p>
      </div>
      @if (showMagnifiedViewPopupData.isMagnifiedView===false) {
        <div class="display" (click)="showMagnifiedView()">
          <img class="expand-img" src="./assets/img/maximize_icon.png" alt="Expand image" />
        </div>
      }
      @if (showMagnifiedViewPopupData.isMagnifiedView===true) {
        <div class="display" (click)="showMagnifiedView()">
          <img class="expand-img" src="./assets/img/minimize_icon.png" alt="Expand image" />
        </div>
      }
    </div>
  </div>

  <div class="row mr-2 tile-body">
    <div class="col-xl-2 col-lg-2 col-md-3 mb-4 column">
      <div class="left-tile">
        <div class="tile mtd-tile">
          <p class="mtd-ytd-text">MTD:</p>
          <p class="mtd-ytd-value" [ngClass]="{'magnified-view': showMagnifiedViewPopupData.isMagnifiedView}">{{ mtd }}%</p>

        </div>
        <div class="tile mb-1">
          <p class="mtd-ytd-text">YTD:</p>
          <p class="mtd-ytd-value" [ngClass]="{'magnified-view': showMagnifiedViewPopupData.isMagnifiedView}">{{ ytd }}%</p>

        </div>
        <div class="tile mb-1">
          <p class="cases-text">Nurse- Scanned Cases:</p>
          <p class="cases-value" [ngClass]="{'magnified-view': showMagnifiedViewPopupData.isMagnifiedView}">{{ nurseScanned }}</p>

        </div>
        <div class="tile mb-1">
          <p class="total-cases-value">{{ totalCases }}%</p>
          <p class="total-cases-text">of Total Cases</p>
        </div>
      </div>
    </div>
    <div class="col-xl-10 col-lg-8 col-md-6 column preference-card-graph">
      <app-line-chart-clinical [lineChartData]="lineChartData" [chartMetaData]="chartMetaData">
      </app-line-chart-clinical>
    </div>
  </div>
</div>