<div class="reset-pwd-success">
    <div class="row">
            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-1 col-xs-1"></div>
            <div class="col-lg-4 col-xl-4 col-md-6 pl-0 pr-0 col-sm-10 col-xs-10 mt-8">

                    <div class="col-lg-12">
                            <div class="success-form">
                                    <div class="heading-div">
                                            <h1 class="header1 f-60">Password is saved</h1>
                                            <label class="sub-title sub-title1">Your password has been
                                                    saved.Remember this for the future login.</label>
                                    </div>

                                    <div class="button-div1">

                                            <button type="button" class="btn button-primary-large"
                                                    (click)="goToLogin()">Log in</button>
                                    </div>
                            </div>


                    </div>
            </div>
    </div>
</div>