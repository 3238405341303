<div class="row">
  <div class="kpi-tile mb-4 mr-4">
    <div class="mb-4">
      <app-preference-card-overview-tile></app-preference-card-overview-tile>
    </div>
    <div class="mb-4">
      <app-product-usage-overview-tile></app-product-usage-overview-tile>
    </div>
  </div>
  <div class="graph-tile">
    <div class="row">
      <div class="col-xl-6 col-lg-5 col-md-9">
        <div class="card mb-4">
          <div class="card-body pt-0">
            <app-preference-card-accuracy-tile [showMagnifiedViewPopupData]='showMagnifiedViewPopupData' (showMagnifiedPopup)="showMagnifiedPopup($event)"></app-preference-card-accuracy-tile>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-5 col-md-9">
        <div class="card mb-4 custom_width">
          <div class="card-body  pt-0 ">
            <app-or-scanning-tile [showMagnifiedViewPopupData]='showMagnifiedViewPopupData' (showMagnifiedPopup)="showMagnifiedPopup($event)"></app-or-scanning-tile>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-5 col-md-9">
        <div class="card mb-4">
          <div class="card-body pt-0 custom_height">
            <app-products-usage-returns-tile  [showMagnifiedViewPopupData]='showMagnifiedViewPopupData' (showMagnifiedPopup)="showMagnifiedPopup($event)"></app-products-usage-returns-tile>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-5 col-md-9">
        <div class="card mb-4 custom_width">
          <div class="card-body pt-0 custom_height ">
            <app-financial-overview-tile [showMagnifiedViewPopupData]='showMagnifiedViewPopupData' (showMagnifiedPopup)="showMagnifiedPopup($event)"></app-financial-overview-tile>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
@if (showMagnifiedViewPopupData.tile !== '') {
  <app-tile-magnifier [showMagnifiedViewPopupData]='showMagnifiedViewPopupData' (showMagnifiedTilePopup)="showMagnifiedPopup($event)">
  </app-tile-magnifier>
}
@if (isDisplayFilter) {
  <app-tile-filter (closeFilter)="isDisplayFilter = false" (appliedFilter)="applyFilter($event)" [filterConfig]="filterConfig"></app-tile-filter>
}