<div class="search-bar" [ngClass]="{'showhide' : toggleSearchBar == true}">
    <div class="input-group">
      <p-autoComplete #searchAutocomplete class="customSearchAutocomplete f-18 form-control textbox book"
                      [(ngModel)]="searchQuery" [type]="'search'" loading="true"
                      (onFocus)="showSuggestions()" [suggestions]="autoCompleteSuggestionList"
                      field="fullDescription" (completeMethod)="updateSuggestions($event)"
                      (onSelect)="navigateToResults()" (keyup.enter)="navigateToResults()"
                      [size]="30" [minLength]="3" placeholder="Search product or SKU"
                      [dropdown]="showAutoCompleteDropdown">
        <ng-template let-searchQuery pTemplate="item">
          <div class="ui-helper-clearfix" [ngClass]="{'history':searchQuery.source === 'history'}">
            <em class="material-icons history">history</em>
            <div *ngIf="searchQuery.source === 'history'">
              {{searchQuery.data.fullDescription}}
            </div>
            <div *ngIf="searchQuery.source === 'response'">
              <span *ngIf="searchQuery.data.fullDescription.substring(0,searchQuery.query.length).toLowerCase() == searchQuery.query.toLowerCase()">{{searchQuery.data.fullDescription.substring(0,searchQuery.query.length)}}</span>
              <span *ngIf="searchQuery.data.fullDescription.substring(0,searchQuery.query.length).toLowerCase() == searchQuery.query.toLowerCase()" class="dark-grey">{{searchQuery.data.fullDescription.substring(searchQuery.query.length,searchQuery.data.length)}}</span>
              <span *ngIf="!(searchQuery.data.fullDescription.substring(0,searchQuery.query.length).toLowerCase() == searchQuery.query.toLowerCase())"> {{searchQuery.data.fullDescription}}</span>
            </div>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>
  
  <p-dialog [(visible)]="showMultitenanceError" styleClass="customDialog common-dialog confirmation-dialog" [closable]="false" [modal]="true" [responsive]="true" [minY]="70" [baseZIndex]="10000">
    <p-header>
      <div class="text-right" >
        <em class="material-icons close-icon" (click)="showMultitenanceError=false">close</em>
      </div>
    </p-header>
    <div class="dialog-body-content">
      <p class="alert-text">{{ adam_labels.error_messages.invalidTenantError }}</p>
    </div>
    <p-footer>
        <div class="padding-bottom-custom">
            <button type="button" class="btn button-primary-small semibold button-dimensions" (click)="showMultitenanceError=false;">{{adam_labels.common.okayButton}}</button>
          </div>
          
    </p-footer>
  </p-dialog>
  