<div class="filter-container">
  <p-dialog [(visible)]="display" styleClass="customDialog" [style]="{ width: '36vw',padding:'0' }" [closable]="false" [modal]="true" [responsive]="true" [minY]="70" [baseZIndex]="1000">
    <p-header >
      <div class="col-11 float-start filter-header">
        Filter - {{ filterList.tileName }}
      </div>
      <div class="float-end col-1">
        <em class="material-icons close-icon" (click)="close()">close</em>
      </div>
    </p-header>
    <div class="dialog-body-content tile-filters">
      @for (filter of filterList.tileFilters; track filter; let i = $index) {
        <div class="filter-group float-start">
          <div class="float-start filter-heading w-100">{{filter.fieldName}}</div>
          @if (filter.type==='RadioList') {
            <div class="filter-radioList w-100 float-start">
              @for (field of filter.values; track field) {
                <div class="list-item w-100 mb-2 ps-2">
                  <p-radioButton [inputId]="field.id" name="field" [value]="field" label={{field.name}} [(ngModel)]="filter.selectedValue" (onClick)="radioFilterChange(filter)">
                  </p-radioButton>
                </div>
              }
            </div>
          }
          @if (filter.type==='Checkbox') {
            <div class="filter-checkboxList w-100 float-start">
              @for (field of filter.values; track field) {
                <div class="list-item w-100">
                  <div class="p-field-checkbox mb-2 ps-2 pb-1  float-start" [ngClass]="{'col-4':filter.values?.length>1,'col-12':filter.values?.length===1}">
                    <p-checkbox [inputId]="field.id" name="field" [value]="field" label={{field.name}} [(ngModel)]="filter.selectedValue" pTooltip={{field.name}} tooltipStyleClass="filter-tooltip" tooltipPosition="top" (onChange)="checkboxFilterChange(filter)">
                    </p-checkbox>
                  </div>
                </div>
              }
            </div>
          }
          @if (filter.type==='SearchBox') {
            <div class="filter-checkboxList w-100 float-start">
              <div class="p-field-dropdown mb-2 ps-2 row">
                <div class="input-group">
                  <span class="input-group-addon float-start pt-2 ps-2">
                    <em class="material-icons f-10 serach-history-icon search-icon-color">search</em>
                  </span>
                  <p-autoComplete #autocomplete class="col-5 p-0  clinical-autocomplete-filter" [(ngModel)]="filter.selectedValue" [suggestions]="filter.values" field="name" (completeMethod)="filterQueryChange($event,filter)" placeholder="Search {{filter.fieldName}}..."
                    [dropdown]="false" (onSelect)="dropdownFilterChange(filter)" (onUnselect)="dropdownFilterChange(filter)" [multiple]="true">
                  </p-autoComplete>
                  <span class="input-group-addon float-start pt-2 ps-2">
                    <em class="material-icons f-5 close-icon" (click)="clearFilter(filter)">close</em>
                  </span>
                </div>
              </div>
            </div>
          }
          @if (filter.type==='NumberRange') {
            <div class="filter-number-range w-100 float-start">
              <div class="list-item w-100">
                <div class="row">
                  <div class="float-start col-1 pe-0 pt-0 range-limit-first">{{filter.minValue}}</div>
                  <div class="col-10 pt-2">
                    <p-slider [range]="true" [(ngModel)]="filter.selectedValue" [min]="filter.minValue" [max]="filter.maxValue">
                    </p-slider>
                  </div>
                  <div class="float-start col-1 p-0 range-limit">{{filter.maxValue}}</div>
                </div>
              </div>
              <div class="w-100 number-range-displayer">
                <div class="data-reading">
                  <div class="value-item-header">min price</div>
                  <div class="value-item-reading">@if (filter.selectedValue[0] ||filter.selectedValue[0]===0) {
                    <span>$</span>
                    }{{filter.selectedValue[0]}}
                  </div>
                </div>
                <div class="data-seperator">-</div>
                <div class="data-reading">
                  <div class="value-item-header">max price</div>
                  <div class="value-item-reading">@if (filter.selectedValue[1] || filter.selectedValue[1]===0) {
                    <span>$</span>
                    }{{filter.selectedValue[1]}}
                  </div>
                </div>
              </div>
            </div>
          }
          @if (filter.type==='DateRange') {
            <div class="filter-number-range w-100 float-start">
              <div class="list-item w-50 float-start ps-3 pe-7">
                <div><label>From</label></div>
                <mat-form-field class="analytic-date-picker" appearance="fill">
                  <input matInput [(ngModel)]="filter.from" [max]="maxDate" [matDatepicker]="startDate" (dateChange)="dateFromChanged(filter)" [disabled]="disableRangeSelection">
                  <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                  <mat-datepicker #startDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="list-item w-50 float-start pe-10">
                <div> <label>To</label></div>
                <mat-form-field class="analytic-date-picker" appearance="fill">
                  <input matInput [(ngModel)]="filter.to" [min]="filter.from" [max]="maxDate" [matDatepicker]="endDate" [disabled]="disableRangeSelection">
                  <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          }
        </div>
      }
    </div>
    <p-footer>
      <div class="filter-btn-group">
        <button type="button" class="btn button-primary-small semibold" (click)="validateDateRange()">
          Apply
        </button>
      </div>
    </p-footer>
  </p-dialog>
</div>

<p-dialog [(visible)]="showMessage" showHeader="false" [modal]="true" [responsive]="true" [style]="{width: '460px'}" [minY]="70" [baseZIndex]="10000" header="Validation Error" styleClass="customDialog">
  <div class="validation-error-text">
    {{errorMessage}}
    <br>
    </div>
    <p-footer>
      <button class="button-primary-large" (click)="showMessage=false">Ok</button>
    </p-footer>
  </p-dialog>