import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { ErrorHandlerInterceptor } from './core/error-handler.interceptor';
import { provideHttpClient, withFetch, HTTP_INTERCEPTORS, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { TokenHandlerInterceptor } from './core/token-handler.interceptor';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

export const appConfig: ApplicationConfig = {
    providers:
        [
            { provide: LocationStrategy, useClass: HashLocationStrategy },
            provideRouter(routes),
        ]
};

export const AdamConf = {
    // app settings
    VERSION: 'Aita Ui - 2.0.1',
    ROWS_PER_PAGE: '10',
    SEARCH_HISTORY_COUNT: 20,
    LOADER_EXCLUSIONS: [
        'products/search',
        'api/v1/productissues/products',
        'config-params',
        'cart/cartSearch',
        'cart/getCartItems',
        'cart/save-session',
        'api/v1/notify/alerts/latest',
        'api/v1/notify/alerts/notifications',
        'api/v1/notify/alerts/system'
    ],
    MENU_EXCLUSIONS: ['profile', 'tabpanel', 'analytics'],
    NAV_MENU_ALERT_FIELD: 'Alerts',
    MYPAGEKIOSKS: ['materials handler'],
    HideQlikDashboardRoles: ['circulating nurse', 'nurse manager'],
    assessmentDeviceUtilizationRatio: {
        kiosk: 1,
        sutureHub: 4,
        smartShelf: 1
    },
    RESPONSE_CODE: {
        SUCCESS: '2001',
        FAIL: '4001'
    },
    timeLeftToRefreshToken: 900000, // 15 mins in milli secs
    basicAuthToken: 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
    OPS_HEADER_KEY_FOR_FACILITY_ID: 'tenant-id',
    recaptchaSiteKey: '6Len1kUjAAAAAE--9mW6bUBHJzBlkOLz4IS1pj9O',

    // DEFAULT TOPICS MY PAGE
    Default_Nurse_Topics: ['waste', 'recalled', 'procedures', 'preferences'],
    Default_Materials_Manager_Topics: ['waste', 'recalled', 'replenishment', 'returns'],
    Default_Admin_Topics: ['waste', 'preferences', 'procedures', 'discrepancies'],
    Default_Nurse_Links: ['Case Status', 'Inventory Levels', 'Reconcile Tool', 'Product Issues'],
    Default_Materials_Links: ['Order Management', 'Inventory Levels', 'Replenish Tool', 'Product Issues'],

    // common
    common: {
        cancel: 'Cancel',
        close: 'Close',
        matClose: 'close',
        submit: 'Submit',
        emailLabel: 'Email',
        undo: 'Undo',
        send: 'Send',
        firstNameLabel: 'First name',
        lastNameLabel: 'Last name',
        roleLabel: 'Role',
        okayButton: 'Okay',
        hospitalTab: 'Hospital Page',
        myPage: 'My Page',
        controlTower: 'Control Tower',
        readMore: 'Read more',
        today: 'Today',
        tomorrow: 'Future',
        low: 'LOW',
        med: 'MED',
        high: 'HIGH',
        print: 'Print',
        printAll: 'Print all',
        previous: 'Previous',
        next: 'Next',
        signIn: 'Sign in',
        back: 'Back',
        refresh: 'Refresh',
        yes: 'Yes',
        no: 'No',
        noRecordsFound: 'No records found!',
        selectAll: 'Select All',
        clear: 'Clear',
        edit: 'Edit',
        printAllBtn: 'Print all',
        printBtn: 'Print',
        removeBtn: 'Remove',
        acknowledgeBtn: 'Add',
        asterisk: '*',
        selectDate: 'Select a date',
        prev: 'Previous',
        removeText: 'Remove',
        actionText: 'Action',
        markCmplt: 'Mark as complete',
        noRecord: 'No records found!',
        createdOn: 'Created on',
        save: 'Save',
        remove: 'Remove',
        all: 'All',
        kiosks: 'Kiosks',
        smartShelf: 'Smart Shelf',
        sutureHubs: 'Suture Hubs',
        partialPick: 'Partial Pick',
        updatedOn: 'Updated on',
        no_record_issue: 'No, record issue',
        remove_confirmation: 'Did you remove',
        sutures: 'sutures?',
        suture: 'suture?',
        row: 'Row',
        column: 'Column',
        abort: 'Abort',
        confirmationPrompt: 'Are you sure you want to proceed?',
        clinical: 'Clinical',
        updateUnlock: 'Update & Unlock',
        ascending: 'ASC',
        descending: 'DESC',
        confirm: 'Confirm',
        locked: 'LOCKED',
        scaled: 'SCALED',
        aitaAdmin: 'AITA Admin',
        materialsAdmin: 'Materials Admin',
        kiosk: 'kiosk',
        kioskDevice: 'KIOSK',
        smartShelfDevice: 'SMART_SHELF',
        smartSectionDevice: 'SMART_ROOM',
        yesValue: 'yes',
        done: 'Done',
        sutureHubDevice: 'SUTURE_HUB',
        sutureHub: 'suture-hubs',
        shelfDevice: 'smart-shelf',
        sectionDevice: 'smart-section',
        recall: 'recall',
        expiry: 'expiry',
        proceed: 'Proceed',
        noneFound: 'None Found',
        suggestions: 'Suggestion(s)',
        recordProductIssue: 'Record Issue',
        editProductIssue: 'Edit Issue',
        editProfile: 'Edit Profile',
        login: 'Login',
        specialty: 'specialty',
        surgeon: 'surgeon',
        product: 'product',
        procedureType: 'procedureType',
        inventoryNotTracked: 'INVENTORY_NOT_TRACKED',
        inventoryNotTrackedSmallCase: 'Inventory not tracked'
    },

    // error messages
    error_messages: {
        emailValidationError: 'Please enter a valid email address.',
        emailRequiredError: 'Email address is required.',
        emailAlreadyUsed: 'This email id is already used.',
        lastNameReq: 'Last name is required.',
        firstNameReq: 'First name is required.',
        invalidFirstName: 'Enter a valid first name.',
        maxLength: 'Exceeds maxlength value 100',
        invalidLastName: 'Enter a valid last name.',
        invalidRole: 'Role is required.',
        invalidPermissionType: 'Permission type is required.',
        invalidRoleCSV: 'Invalid role uploaded, please select',
        invlaidPermissionType: 'Invalid permission type uploaded, please select',
        duplicateEmail: 'Duplicate email',
        invalidValue: '*Invalid value',
        nameValidationError: 'Please enter a valid first name.',
        lnameValidationError: 'Please enter a valid last name.',
        invalidTenantError: 'Make sure you belong to a valid institution and facility to access this functionality',
        invalidCaptcha: 'Google captcha not checked or in error'
    },

    // user_management_module
    user_management_module: {
        signinHeader: 'Sign in',
        signinButton: 'Sign in',
        reqAccessinSignin: `Don't have an account? Request access.`,
        forgotPwd: 'Forgot password?',
        forgotPasswordHeader: 'Forgot your password? ',
        forgotPasswordSubHeader: `Please enter your email address. We'll send you a link to reset your password.`,
        forgotPasswordSuccessHeader: 'Got it!',
        forgotPasswordSuccessSubHeader: 'You will receive an email with a link to reset your password.',
        resetPasswordHeader: 'Reset your password',
        createPwd: 'Create new password',
        confirmPwd: 'Confirm new password',
        resetPwdSuccessHeader: 'Success!',
        resetPwdSuccessSubHeader: 'Your new password has been saved.',
        setUpPasswordHeader: 'Welcome,',
        setUpPasswordSubHeader1: 'Please create a unique password. Passwords should be 8-30 characters and a combination',
        setUpPasswordSubHeader2: ' of letters, numbers, and special characters.',
        setUpPasswordSuccessButton: 'Return to Sign in',
        reqAccessHeader: 'Create an account',
        reqAccessSubHeader: `First, you'll need to request access.`,
        reqAccessSuccessHeader: 'Thanks!',
        reqAccessSccessSubHeader: `When your request is approved, you'll receive an email to create an account.`,
        selectRole: 'Select a role.',
        signinSetupHeader: 'Your account has been created!',
        signinSetupSubHeader: 'Please sign in to begin using AITA.',
        invalidCredentials: ' Invalid Credentials!'
    },
    // my_page
    my_page: {
        notifMsg: 'Increase in procedure safety'
    },
    // replenishment_module
    replenishment_module: {
        notifMsg: 'units need to be replenished.',
        notifbtn: 'Replenish now',
        totalStacks: 'total stacks',
        replenish_select_top_header: 'REPLENISH',
        replenish_select_top_header1: 'Replenish',
        replenish_select_header: 'Select unit',
        replenish_select_subHeader: 'Select a unit to replenish. You can also print a report of products to replenish.',
        replenish_select_subHeader_scanner: 'Select a unit to replenish',
        replenish_select_subHeader_scanner2: 'Unlock the doors on the kiosk through the touchscreen. ' +
            'Select the highlighted slots below to see the products to replenish.',
        replenish_select_subHeader_scanner3: 'Scan the boxes for each stack by locating the barcode(s) on the box ' +
            'and pressing the scan button on this device.',
        replenishBtn: 'Replenish',
        viewAll: 'View all',
        replenish_stack_top_header: 'REPLENISH',
        replenish_stack_sub_header_kiosk: 'Unlock the kiosk doors using the touchscreen. ' +
            'Select the highlighted stack(s) below for more information.',
        replenish_stack_sub_header_suture_hub: 'Select the highlighted stack(s) below for more information and ' +
            'refill the hub drawer with the corresponding product.',
        replenish_stack_sub_header_smart_shelf: 'Select the highlighted stack(s) below for more information and ' +
            'refill the hub drawer with the corresponding product.',
        replenish_stack_success_header: 'Great news!',
        replenish_stack_success_sub_header: `All units are replenished. We'll notify you when any units are running low.`,
        productLabel: 'PRODUCT',
        productCode: 'PRODUCT CODE',
        boxLabel: 'BOXES TO ADD',
        actionlabel: 'ACTION',
        markAsComplete: 'Mark as complete',
        issuesLabel: 'Issues',
        recordIssue: 'Record issues',
        backBtn: 'Back',
        completedOn: 'Completed on',
        at: 'at',
        by: 'by',
        completedTodayAt: 'Completed today at',
        popupPreHeader: 'REPLENISH',
        popupSubHeader: 'Please continue to replenish. If you’re unable to, provide a reason below:',
        popupButtonText: 'Continue to replenish',
        popupSuccessSubHeader: 'Replenish issue #',
        popupSuccessSubHeader2: ' has been recorded.',
        popup_header2: 'Document extra sutures',
        popup_header3: 'Please count and place them in the restock bin.',
        productName: 'PRODUCT NAME',
        replenishedBoxes: 'REPLENISHED BOXES',
        extraSutures: 'EXTRA RESTOCKED SUTURES',
        restockedSuture: 'Restocked sutures',
        restockedSuture1: 'restocked sutures',
        recordedIssue: 'Recorded issue',
        popupHeader1: `You haven't replenished enough of`,
        popupHeader2: '',
        scanText: 'Unlock the kiosk doors using the touchscreen. Select a stack(s) below to start scanning boxes.',
        scan: 'SCAN',
        productLot: 'PRODUCT LOT',
        barCodePlaceHolder: 'Scan QC Code / Barcode',
        replenishSummaryError: 'Error occurred while retrieving replenishment summary! Please try again.',
        replenishReturnToScanText: 'Touch to Return to Scanning',
        replenishCompleteError: 'Error occurred while completing replenishment! Please try again.',
        replenishCancelError: 'Error occurred while canceling replenishment! Please try again.',
        replenishDeviceSelectionError: 'Error occurred while updating device details! Please try again.',
        onboardingTitle: 'Onboarding Replenishment',
        onboardingSubTitle: 'Select device to replenish the scanned product.',
        onboardingProducTitle: 'Select product to replenish the scanned product.',
        replenishmentTitle: 'Replenishment',
        replenishmentSubstitute: 'Substitute for',
        replenishment: 'Replenishmet',
        replenishmentSubstituteLegend: ' : Denotes for substitute product',
        replenishmentLoaderText: ' Ordered vs Replenished page is being updated - please wait!',
    },
    // reconciliation_module
    reconciliation_module: {
        reconciliation_list_top_header: 'RECONCILIATION',
        reconciliation_list_header: 'Select case',
        reconciliation_list_sub_header: `Select the case you'd like to reconcile.`,
        searchCasenumber: 'Search by case number',
        caseNumber: 'Case Number',
        surgeon: 'Surgeon',
        procedure: 'Procedure',
        dateTime: 'Date/Time',
        location: 'Location',
        reconcile_edit_top_header: 'RECONCILE PRODUCTS',
        reconcile_edit_header: 'case',
        reconcile_edit_sub_header: `Please update the status of the products picked for this surgery.
       Make sure the number wasted and number returned don't exceed the total number picked for this surgery.`,
        reconcile_edit_sub_header1: 'Picked: Products pulled for surgery.',
        reconcile_edit_sub_header2: 'Wasted: Products unable to be used',
        reconcile_edit_sub_header3: 'Returned: Unused products that can be restocked',
        addPdt: 'Add product',
        pickedFrom: 'Picked from ',
        reconcile_edit_error1: '*Invalid value',
        reconcile_edit_error2: '*Select a total picked count.',
        items: 'items',
        noRecordFnd: ' No records found!',
        deleteConfirmation1: 'Are you sure you want to',
        deleteConfirmation2: 'remove this Product?',
        goback: 'Go back',
        productName: 'Product Name',
        productCode: 'product code',
        pickedQuantity: 'Total Picked',
        wastedQuantity: 'Wasted',
        returnedQuantity: 'Returns',
        usedQuantity: 'Total Used',
        backToSelectCase: 'Back',
        back: 'Back',
        alertError: 'Something went wrong! Please go back the case list and start reconciliation',
        reconcile_edit_popupData1: 'The number wasted and the',
        reconcile_edit_popupData2: `number returned can't exceed`,
        reconcile_edit_popupData3: 'the total number picked.',
        reconcile_edit_popupData4: 'picked.',
        reconcile_edit_popupData5: 'Please adjust the values of the highlighted items.',
        reconcile_edit_popupData6: 'the highlighted items.',
        reconcile_review_alertError: 'Something went wrong! Please go back the case list and start reconciliation',
        reconcile_review_goback: 'Go back',
        review: 'REVIEW',
        reconcile_review_header: 'case',
        reconcile_review_sub_header: 'Before submitting, please review that the number picked,' +
            ' the number wasted, and the number returned are correct.',
        reconcile_review_confirmation: 'Are you sure you want to',
        reconcile_review_confirmation1: 'submit?',
        confirmationReject: 'No, go back',
        resubmit: 'Resubmit',
        internetError: 'No internet connection',
        internetErrorMsg: `Make sure you're connected to the internet and then`,
        internetErrorMsg1: 'try again.',
        reconcile_confirmation_header: 'Success!',
        reconcile_confirmation_sub_header: 'The products for this case have been reconciled.',
        reconcile_confirmation_button: 'Reconcile another case',
        done: 'Done',
        reconcile_confirmation_error_header: 'Submission error.',
        reconcile_confirmation_error_sub_header: 'The products for this case have not been reconciled. Please try submitting again. ',
        reconcile_confirmation_error_reconciled: 'This case is already reconciled.',
        noCaseFound: 'No cases found!'
    },
    // staff permission
    staff_permission_module: {
        permission_types_header1: 'Features',
        permission_types_header2: 'User',
        permission_types_header3: 'Admin',
        permission_types_header4: 'Super admin',
        staff_permission_header: 'Staff Permissions',
        staff_permission_sub_header: 'What would you like to do?',
        staff_permission_header1: 'MANAGE USERS',
        staff_permission_header2: 'ADD NEW USER',
        staff_permission_header3: 'APPROVE ACCESS',
        staff_permission_header4: 'View permission types',
        deleteUser: 'Delete user',
        saveChanges: 'Save changes',
        manage_user_deleteConfirm1: 'Are you sure you want to delete',
        manage_user_deleteConfirm2: 'this user?',
        confirmationReject: 'No, go back',
        firstName: 'First Name',
        lastName: 'Last Name',
        role: 'Role',
        permissnionType: 'Permission Type',
        email: 'Email',
        user_success_header: 'Success!',
        user_success_subHeader: 'The user has been deleted.',
        user_success_subHeader1: 'User account has been updated.',
        manage_user_success_header: 'Access granted',
        manage_user_success_failureHeader: 'Access denied!',
        manage_user_success_subHeader: 'New users will receive an email to set up their account.',
        manage_user_success_failureSubHeader: 'Users will receive an email to know their access request is denied.',
        approve_access_error: 'The following request is already approved or rejected.',
        approve_access_error1: 'Field : ',
        approve_access_error2: 'Error Description :',
        approve_access_error3: 'Please enter the valid input to approve or deny the request.',
        approve_access_error4: 'Please select the request.',
        deny: 'Deny access',
        header1: 'First Name',
        header2: 'Last Name',
        header3: 'Role',
        header4: 'Permission Type',
        header5: 'Email',
        allowAccess: 'Allow access',
        approve_access_confirm1: 'Are you sure you want to allow',
        approve_access_confirm2: 'access ?',
        emailValidError: 'Enter a valid email',
        add_user_success_header: 'Access granted',
        add_user_success_subHeader: 'New users will receive an email to set up their account.',
        addUser: 'Add user',
        uploadCSV: 'Upload CSV file',
        csv1: 'CSV file (comma delimited, header required): ',
        csv2: ' First Name, Last Name, Permission Type, Role, Email.',
        csv3: 'sample CSV file.',
        csv4: 'Download',
        addNewUser: 'Add new user',
        firstNameValidError: 'Please enter a valid first name',
        lastNameValidError: 'Please enter a valid last name',
        roleValidError: 'Please select a valid role',
        roleError: 'Invalid role uploaded, please select',
        PTValidError: 'Please select a valid permission type',
        ptError: 'Invalid permission type uploaded, please select',
        emailValidError1: 'Please enter a valid email',
        approveAcess: 'Approve access',
        addNewUsers: 'Add New User',
        editUser: 'Edit User',
        viewUser: 'View User List',
        disableUser: 'Delete User',
        viewRequestList: 'View All Access Request',
        requestAccess: 'Request Access',
    },
    // footer
    footer_module: {
        footer1: 'Customer support',
        footer2: 'Privacy policy',
        footer3: 'Legal notice',
        footer4: 'Pricing disclosure',
        startYear: '2020-',
        jnjTag: 'Johnson & Johnson Services, Inc.',
        azure20tag: 'Azure 2.0'
    },
    // unauthenticated product search
    search_results_module: {
        noRstFound: 'No results found',
        header: 'Search results',
        subHeader: 'You can find',
        subHeader1: 'in the following',
        subHeader2: ' location.',
        header1: 'Device Name',
        header2: 'Device Location',
        header3: 'Item Count',
        header4: 'Bin Location',
        failureHeader1: 'Your search for ',
        failureHeader2: `didn't return any results. `,
        failureHeader3: 'Did you mean ',
        failureSubHeader: 'Search tips',
        error1: 'Try searching by product name or SKU.',
        error2: 'Check your spelling.'
    },

    // user-setup
    acount_settings_module: {
        page_sum: '/2',
        topicTitle: 'Topics that interest you',
        alertsTitle: 'Notifications preferences',
        // profile
        inpHosp: 'Hospital',
        inpTitl: 'Title',
        inpFn: 'First Name',
        inpFnReq: 'First name is required',
        inpLn: 'Last Name',
        inpLnReq: 'Last name is required',
        inpRol: 'Role',
        inpEml: 'Email',
        tim: 'Time Preference',
        timReq: 'Time preference is required',
        dat: 'Date Preference',
        datReq: 'Date preference is required',
        autoRefresh: 'Auto Refresh',
        autoRefreshReq: 'Auto refresh is required',
        accAct: 'Account',
        sof: 'Software',
        ver: 'Version',
        // topic
        accountSettingPara1: 'These are the topics you selected to appear on your homepage.',
        accountSettingPara2: 'Click on the pencil icon to edit those topics.',
        accountSettingParaOnEdit: 'Select the topics you’d like to appear on your homepage: ',
        userSetupPara1: 'The topics you select will appear on your homepage. You can update these topics in your ',
        userSetupPara2: 'Account Settings.',
        selectAllBtn: 'Select all',
        // notification
        accountSettingNotificationPara: 'Would you like to also receive alerts and notifications on your mobile device?',
        userSetupNotificationPara1: 'You’ll receive alerts and notifications on your homepage. Would you also like ',
        userSetupNotificationPara2: 'to receive them on your mobile device?',
        phLabel: 'Phone number ',
        phError: 'Minimum 10 numbers',
        alertLabelYes: 'Yes',
        alertLabelNo: 'No',
        // accountSettings
        title: 'Account Settings',
        tabTopi: 'TOPICS',
        tabPrf: 'PROFILE',
        tabQuk: 'QUICK LINKS',
        tabNoti: 'NOTIFICATIONS',
        // quickLinks
        description1: 'These are the quick links assigned to you based on your job title. You can ',
        description2: 'update them by clicking on the pencil icon.',
        descriptionEdit1: 'These are the quick links assigned to you based on your job title. You can change',
        descriptionEdit2: ' them by selecting new topics below (maximum 4):'
    },
    // inventoryLevels
    inventory_module: {
        title: 'Inventory Levels',
        stackTotal: ' total stack',
        backTo: 'Back',
        tableHead1: 'Levels',
        tableHead2: 'Product Name',
        tableHead3: 'Product Code',
        tableHead4: ' Sutures Available',
        tableHead5: 'Status',
        noCaseFound: 'No cases found!',
        viewOrders: 'View orders',
        replenishNow: 'Replenish now',
        head: 'Kiosk 1 – 2nd Floor, Zone 2',
        stack: 'stack',
        s: 's',
        need: 'need to be replenished.',
        noProduct: 'No product',
        na: 'N/A',
        inventoryValue: 'inventoryValue',
        largeShort: 'Large-Short',
        largeTall: 'Large-Tall',
        smallShort: 'Small-Short',
        smallTall: 'Small-Tall',
        mediumTall: 'Medium-Tall',
        mediumShort: 'Medium-Short',
        large: 'Large',
        small: 'Small',
        medium: 'Medium',
        short: 'Short',
        tall: 'Tall',
        smartRoom: 'SMART_ROOM',
        unlockKiosk: 'Unlock Kiosk',
        aduParUpdate: 'Edit ADU/PAR',
        productSkuUpdate: 'Update SKU',
        removeProduct: 'Remove SKU',
        noInventoryTracked: 'No Inventory Tracked',
        adjustKioskInventory: 'Adjust Kiosk Inventory',
        mock: 'MOCK',
        gridLevelFootnote: 'To unselect a row in the table, please hold down the Ctrl key and click on the row again.',
    },
    // recall tool
    recall_module: {
        titleRecall: 'RECALL TOOL',
        recalledOn: 'Recalled on',
        description: 'Select a unit to remove the recalled product. You can also print a report of the locations of the recalled product.',
        description1: 'Select a unit to remove the recalled product.',
        removedOn: 'Removed on',
        at: 'at',
        by: 'by',
        removedTodayAt: 'Removed today at',
        acknowledgeRecall: 'Add a recall',
        acknowledgeProdRecall: 'Add a product recall',
        prdRecallPara: 'Currently there are no recalls recorded in the system. If you have been notified of a recall, please add it now.',
        // recall-product
        enterRecalledProduct: 'Product details',
        recallProdDesc: 'Please enter the details for the recalled product.',
        // recall-product form
        inputProductCodeSKU: 'Product Code/SKU',
        inputProductCodeSKUErrReq: 'Product Code/SKU is required',
        inputProductCodeSKUErrInv: 'Product not available or invalid product code',
        inputProductCodeSKUErrExist: 'Active recall already exists',
        productLot: 'PRODUCT LOT',
        productLotTitleCase: 'Product Lot',
        recalledSutureCount: 'Recalled Sutures',
        invalidSutureCount: 'Please enter valid suture count!',
        productLotReq: 'Product lot is required',
        productLotInv: 'Invalid product lot',
        backToselect: 'Back',
        expiringOn: 'Expiring on',
        expDescKiosk: 'Unlock the kiosk doors using the touchscreen. Select the highlighted stack(s) below for product information.',
        expDescSutureHub: 'Select the highlighted slots below to see the products to remove from the suture hub.',
        expDescSmartShelf: 'Select the highlighted slots below to see the products to remove from the smart shelf.',
        imgLabel: 'SKU',
        action: 'ACTION',
        removeSutures: 'SUTURES TO REMOVE',
        productCode: 'PRODUCT CODE',
        popupPreHeader: 'RECALL',
        popupHeader1: 'Document recalled sutures not removed',
        popupHeader2: '',
        popupSubHeader: 'Please continue to remove recalled items. If you’re unable to, provide a reason below:',
        popupButtonText: 'Continue to removal',
        popupSuccessSubHeader: 'Recall issue #',
        popupSuccessSubHeader2: ' has been recorded.',
        startedOn: 'Started on',
        recordedIssue: 'Recorded issue',
        popup_header1: 'RECALL',
        updateError: 'Something went wrong! Please try again.',
        initiatedStatus: 'RECALL_INITIATED',
        recalledStatus: 'RECALLED',
        recallToolRemoval: 'Recall Tool Remove',
    },
    // equipment-status
    equipmntStatus_module: {
        title: 'Unit Status',
        desc: '2 machines need your attention.',
        rec: 'Record issue',
        // table
        tblHead1: 'NAME',
        tblHead2: 'LOCATION',
        tblHead3: 'UNIT TYPE',
        tblHead4: 'SERIAL NUMBER',
        tblHead5: 'STATUS',
        tblHead6: 'LAST SUCCESSFUL CHECK',
    },
    // notification
    notificationPage_module: {
        title: 'Notifications',
        all: 'All',
        alerts: 'Alerts',
        oneWeek: '1 Week',
        days: '30 Days',
        replenishNowBtn: 'Replenish now',
        approveNow: 'Approve now',
        removeNow: 'Remove now',
        resolveNow: 'Resolve now',
        noNotification: 'No notifications found!'

    },
    // case-status
    caseStatus_module: {
        title: 'Case Status',
        desc: 'Select a case to see a list of products needed for the surgery.',
        recoIssue: 'Record issue',
        tblHead1: 'Case Number',
        tblHead2: 'Surgeon',
        tblHead3: 'Surgery Date/Time',
        tblHead4: 'Location',
        tblHead5: 'Status',
        changeLocationPopupMessage: 'Please select a dispensing unit:',
        interrupted: 'INTERRUPTED',
        picking: 'PICKING',
        readyForRetrieval: 'READY FOR RETRIEVAL',
        readyForManualPick: 'READY FOR MANUAL PICK',
        retrieved: 'RETRIEVED',
        closed: 'CLOSED',
        error: 'ERROR',
        kioskRetrieved: 'KIOSK RETRIEVED',
        shelfRetrieved: 'SHELF RETRIEVED',
        readyForPicking: 'READY FOR PICK',
        smartRoomRetrieved: 'SMART ROOM RETRIEVED',
        kioskShelfRetrieved: 'KIOSK AND SHELF RETRIEVED',
        kioskSmartRoomRetrieved: 'KIOSK AND SMART ROOM RETRIEVED',
        shelfSmartRoomRetrieved: 'SHELF AND SMART ROOM RETRIEVED',
        pickingDefinition: 'The case has been sent to the KIOSK queue for picking',
        readyForRetrievalDefinition: 'The case is ready to be retrieved from the KIOSK',
        readyForManualPickDefinition: 'The case is ready to be picked from the SMART SHELF',
        retrievedDefinition: 'The case has been retrieved.',
        closedDefinition: 'Case reconciliation has been completed',
        errorDefinition: 'Audit sutures - at least one suture in the case was not able to be scanned',
        interruptedDefinition: 'Manual intervention required. Case was interrupted due to a KIOSK restart',
        kioskRetrievedDefinition: 'Partial pick - KIOSK received, SMART SHELF pending',
        shelfRetrievedDefinition: 'Partial pick - SMART SHELF received, KIOSK pending',
        smartRoomRetrievedDefinition: 'Partial pick - SMART ROOM received, KIOSK and SHELF pending',
        kioskShelfRetrievedDefinition: 'Partial pick - KIOSK and SHELF received, SMART ROOM pending',
        kioskSmartRoomRetrievedDefinition: 'Partial pick - KIOSK and SMART ROOM received, SHELF  pending',
        shelfSmartRoomRetrievedDefinition: 'Partial pick - SHELF and SMART ROOM received, KIOSK  pending',
        readyForPickDefinition: 'The case is ready to be picked from the SMART ROOM',
        KioskRetrievedSmartRoomPendingDefinition: 'KIOSK received, SMART ROOM pending',
        ShelfRetrievedSmartRoomPendingDefinition: 'SHELF received, SMART ROOM pending',
        smartRoomRetrievedKioskPendingDefinition: 'SMART ROOM received, KIOSK pending',
        smartRoomRetrievedShelfPendingDefinition: 'SMART ROOM received, SHELF pending',
        kioskRetrievedSSRPending: 'Partial pick - KIOSK received, SHELF and SMART ROOM pending',
        shelfRetrievedKSRPending: 'Partial pick - SHELF received, KIOSK and SMART ROOM pending',
        smartRoomRetrievedKSPending: 'Partial pick - SMART ROOM received, SHELF and KIOSK pending',
        smartRoomPendingKSRetrieved: 'Partial pick - KIOSK and SHELF received, SMART ROOM pending',
        shelfPendingKSRRetrieved: 'Partial pick - KIOSK and SMART ROOM received, SHELF pending',
        kioskPendingSSRRetrieved: 'Partial pick - SHELF and SMART ROOM received, KIOSK pending',
        move: 'Move',
        errorMessage1: 'Error occurred while updating schedules!',
        errorMessage2: 'Error occurred! Some of the schedules could not be updated.',
        successMessage: 'Schedule(s) successfully updated.',
        tblHead6: 'OR room | Surgery Date/Time',
        rowsPerPage: 15,
        kiosk: 'KIOSK',
        smartShelf: 'SMART_SHELF',
        smartRoom: 'SMART_ROOM',
        external: 'EXTERNAL',
        partialPick: 'PARTIAL_PICK',
        kioskTileLabel: 'Cases with Sutures Covered by Kiosk',
        smartShelfTileLabel: 'Cases with Sutures Covered by Smart Shelf',
        smartRoomTileLabel: 'Cases Covered by Smart Room',
        partialPickTileLabel: 'Cases Covered by Partial Pick',
        moveUnpicked: 'Move Unpicked',
        moveUnPickedCases: 'Move Unpicked Cases',
    },
    // Product-list
    productList_module: {
        name: 'Dr. Jhonson case #',
        backToCS: 'Back',
        ready: 'Ready for manual pick',
        dateTime: 'Surgery Date/Time:',
        print: 'Print list',
        thead1: 'PRODUCT LIST ',
        thead2: 'PRODUCT CODE',
        thead3: 'NUMBER OF SUTURES',
        thead4: 'PRODUCT LOCATION',

        subFor: 'Substitution for',
        brand: '4-0 PERMA-HAND SILK A183',
        size: 'Back 18inch 45cm'
    },
    // Ordermanagement
    orderManagement_module: {
        title: 'Order Management',
        backTo: 'Back',
        desc: 'Select an order to review the details.',
        noCaseFound: 'No cases found!',
        thead1: 'Order Number',
        thead2: 'Quantity',
        thead3: 'Date/Time',
        thead4: 'Placed By',
        thead5: 'Status',
        // orderRecommendation
        orderPlaced_title: 'Order Placed',
        orderRecommendation_title: 'Order Recommendation',
        order: 'Order',
        orderRecommendation_desc: 'Select an order to review the details.',
        productOrd: 'to be ordered.',
        adjustVal: '*Adjust values to total picked.',
        totalPick: '*Select a total picked count.',
        changeSave: 'Changes saved',
        ordrPlcd: 'Order Placed',
        close: 'Close',
        orderRecommendation_thead1: ' ',
        orderRecommendation_thead2: 'Product Name',
        orderRecommendation_thead3: 'Product Code',
        orderRecommendation_thead4: 'Boxes',
        orderRecommendation_thead5: ' ',
        omsNumberMinChar: 3,
        cartExpiryReminderTime: 2, // time in mins, will display reminder n mins before expiry
        cartExpiryReminderTimeInSecs: 120, // time in secs, will display reminder n secs before expiry
        cartSyncErrorText: 'Error occurred while syncing cart data! Please try again.',
        needMoreTimeBtn: 'Need more time',
        cartTableHeader1: 'Product Name',
        cartTableHeader2: 'Product Code',
        cartTableHeader3: 'Current Qty.',
        cartTableHeader4: 'Average Daily Use',
        cartTableHeader5: 'PAR',
        cartTableHeader6: 'Boxes',
        cartTableHeader7: 'Estimated Price(USD)',
        cartTableHeader7print: 'Estimated Price (USD)',
        cartTableHeader8: 'Remove From Cart',
        cartLoaderText: 'Cart is being updated - please wait!',
        cartEmptyLine0: 'No products identified as requiring re-order.',
        cartEmptyLine1: 'No Suture System products identified as requiring re-order.',
        cartEmptyLine2: `To add items manually, use the 'Add to Cart' or 'Medium Inventory Level' buttons.`,
        cartEmptyLine3: 'No Smart Room products identified as requiring re-order.',
        mediumInventoryEmptyLine: 'No products identified',
        proceedToCartButtonText: 'Proceed to Cart',
        orderUpdatePopupText1: 'Enter OMS number for order submitted on',
        cartInUseLine1: 'Cart can only be used by one user at a time.',
        cartInUseLine2: 'Cart is currently in use by',
        cartInUseLine3: 'Would you like to clear their current session and proceed to the cart?',
        mediumInventoryToggleButton: 'Medium Inventory Level',
        mediumTableHeader1: 'Est. Days to PAR',
        mediumTableHeader2: 'Move To Cart',
        removeMessagePart1: 'Are you sure you want to remove product ',
        removeMessagePart2: 'from cart?',
        titleAddToCart: 'Add to Cart',
        btnAddToCart: 'Add to Cart',
        submit: 'Submit Order',
        cartSubmitted: 'Your order was successfully submitted.',
        printAndClose: 'Print & Close',
        emptyCartSearchText: 'Begin searching for products to be added to cart manually.',
        orderHistoryEmptyLine: 'No order history data available.',
        orderHistoryLoaderText: ' Order History is being updated - please wait!',
        historyTableHeader1: 'SELECT ALL',
        historyTableHeader2: 'ORDER #',
        historyTableHeader3: 'PLACED BY',
        historyTableHeader4: 'BOXES ORDERED',
        historyTableHeader5: 'BOXES RECEIVED',
        historyTableHeader6: 'ORDER DATE',
        historyTableHeader7: 'STATUS',
        historyTableHeader8: 'ESTIMATED COST (USD)',
        historyPending: 'Pending',
        // order history update order number
        popupHeading: 'Enter OMS number for order submitted on ',
        done: 'Done',
        errorMessageForOrderUpdate: 'Error: The order number entered is invalid. Please confirm and try again.',
        errorPopupMessage: 'An unexpected error occurred!',
        submitErrorMessage: 'Error occurred while submitting order! Please try again.',
        cartLoadErrorMessage: 'Error occurred while loading cart! Please try again.',
        orderUpdateErrorMessage: 'Error occurred while updating order! Please try again.',
        confirmationPopupText: 'Are you sure you want to',
        historyRowsPerPage1: 10,
        historyRowsPerPage2: 25,
        historyRowsPerPage3: 50,
        historyDefaultSortField: 'creationDate',
        historyDefaultSortDirection: 'DESC',
        historyDetailsTableHeader1: 'PRODUCT NAME',
        historyDetailsTableHeader2: 'PRODUCT CODE',
        submitted: 'SUBMITTED',
        processing: 'PROCESSING',
        historySuccess: 'SUCCESS',
        historyError: 'ERROR',
        historyCancel: 'CANCEL',
        historySearchNoMatches: 'No matches',
        cancelled: 'CANCELLED',
        historyTableHeader9: 'ESTIMATED PRICE (USD)',
        cartSubmitErrorMessage: 'Found product(s) with 0 boxes.',
        completed: 'COMPLETED',
        defaultPageNo: 0,
        defaultStatusFilter: ['PROCESSING', 'SUBMITTED'],
        orderInProgressError: 'Another order is already in progress. Please try again later.',
        submitCart: 'Submit Cart',
        editOrderLineItem: 'Edit Order Hisory Line Item',
        updateOrder: 'Update Order',
    },
    expiryTool_module: {
        title: 'EXPIRE TOOL',
        select: 'Expiring by Product',
        desc: 'Select a product to remove from inventory.',
        expiredSutureCount: 'Estimated sutures to remove',
        batchNumber: 'Product Lot',
        invalidSutureCount: 'Please enter a valid count!',
        desc1: 'Select a unit to remove the expired product. You can also print a report of the locations of the expired product.',
        desc2: 'Select a unit to remove the expired product.',
        noprod: 'No products found!',
        noUnits: 'No units found!',
        grtNws: 'Great news!',
        grtNwsDesc: 'There are no expired products. We’ll notify you when any expiring products need to be removed.',
        thead1: ' ',
        thead2: 'Product Name',
        thead3: 'Product Code',
        thead4: 'Boxes',
        thead5: ' ',
        expire_list_thead1: 'Product Description',
        expire_list_thead2: 'Product Code',
        expire_list_thead3: 'Quantity',
        expire_list_thead4: 'Expiration Date',
        expire_list_thead5: 'Remove By',
        expire_list_thead6: 'Product Lot',
        popupPreHeader: 'EXPIRE',
        popupHeader1: 'Document expired sutures not removed',
        popupHeader2: '',
        popupSubHeader: 'Please continue to remove expired items. If you’re unable to, provide a reason below:',
        popupButtonText: 'Continue to removal',
        popupSuccessSubHeader: 'Expire issue #',
        popupSuccessSubHeader2: ' has been recorded.',
        recordedIssue: 'Recorded issue',
        startedOn: 'Started on',
        at: 'at',
        popup_header1: 'EXPIRY',
        all: 'all',
        updateError: 'Something went wrong! Please try again.',
        expiryByLocationHeading: 'Update removed batches by location',
        expiryByLocationPrint: 'Print by Location',
        quantityErrorMessage: 'Please enter a valid count',
        location: 'Location',
        productCode: 'Product code',
        productLot: 'Product lot',
        expiryDate: 'Expiration Date',
        systemQuantity: 'Estimated System Quantity',
        checked: 'Checked?',
        quantityRemoved: 'Quantity Removed',
        expirePrinitMessage: 'Use this list as a reference to remove expired product from AITA units. Once finished, go back to the website to notify the system that you have completed removal of these products.',
        tabName: '30 DAYS',
        duration: 30,
        expiryLegend: ': Denotes for substitute product',
        expirySubstituteLabel: 'Substitute product of',
        removeExpiryProduct: 'Remove Expiry Product',
    },
    ServicesAndRepair: {
        title: 'Novant Health Medical Park',
        desc: 'Winston-Salem, North Carolina - 00007008',
        cnt: 'No contact signed',
        noCaseFound: 'No cases found!',
        thead1: 'Date',
        thead2: 'Activity',
        thead3: 'Activity number',
        thead4: 'Notes',
        addactivity: 'Add Activity'
    },
    profile: {
        location: 'Location',
        contract: 'Contract',
        accountno: 'Account number',
        revenue: 'Revenue',
        archetype: 'Archetype',
        accountowner: 'Account owner',
        hospitalcontact: 'Hospital contact',
        devices: 'Devices',
        notes: 'Notes',
        inpNotesReq: 'Notes is required',
        inpArcheReq: 'Archetype is required',
        inpExecutiveReq: 'Executive name is required',
        inpExecutiveemailReq: 'Executive email is required'

    },
    print: {
        replenishNote1: 'Use this list as reference to replenish this unit. Once finished, go back to the website to',
        replenishNote1_all: 'Use this list as reference to replenish these units. Once finished, go back to the website to',
        replenishNote2: ` notify the AITA system that you've completed replenishment.`,
        recallNote1: 'Use this list as reference to remove recalled products from AITA units. Once finished, go back',
        recallNote2: ` to the website to notify the system that you've completed removal of these products.`,
        expireNote1: 'Use this list as a reference to remove expired products from AITA units. Once finished, go back',
        expireNote2: ` to the website to notify the system that you've completed removal of these products.`,
        orderNote1: 'Use this list as reference to place an order. Once finished, go back to the website',
        orderNote2: 'to notify the AITA system about any adjustments that you’ve made to the',
        orderNote3: 'recommended order and that you placed the order.',
        caseStatusNote: 'Use this list as a reference to pick cases manually.',
        recallHeaderUnit: 'UNIT NAME/LOCATION',
        productCodeHeader: 'PRODUCT CODE',
        productNameHeader: 'PRODUCT NAME',
        boxesOrderedHeader: 'BOXES ORDERED',
        boxesReceivedHeader: 'BOXES RECEIVED',
        estimatedPriceHeader: 'ESTIMATED PRICE (USD)',
        status: 'STATUS',
        slotHeader: 'SLOT',
        quantityHeader: 'QUANTITY',
        removeHeader: 'REMOVE',
        slotsTotal: 'slots total',
        totalStack: 'total stack',
        totalStacks: 'total stacks',
        boxes: 'boxes',
        box: 'box',
        items: 'items',
        item: 'item',
        sutures: 'sutures',
        suture: 'suture',
        stackHeader: 'STACK',
        units: 'units',
        Units: 'Units',
        unit: 'unit',
        recall: 'recall',
        expire: 'expire',
        replenish: 'replenish',
        orderPlaced: 'ORDER PLACED',
        order: 'order',
        createdOn: 'Created on',
        kiosk: 'KIOSK',
        sutureHub: 'SUTURE_HUB',
        smartShelf: 'SMART_SHELF',
        single: 'single',
        all: 'all',
        caseStatus: 'Case Status',
        caseStatusAll: 'Case Status All',
        productListHeader: 'PRODUCT LIST',
        numberSutures: 'NUMBER OF SUTURES',
        productLocation: 'PRODUCT LOCATION',
        orderHistory: 'orderHistory',
        orderHistoryDetails: 'orderHistoryDetails',
        cart: 'Cart',
        expireByLocation: 'expireByLocation',
        smartRoom: 'SMART_ROOM',
    },
    assessment_tool_module: {
        sutHeader: 'Assessment tool',
        sutSubHeader: 'Complete all mandatory fields below to assess the compatibility of a hospital with the AITA system.',
        sutTitle: 'Suture Utilization',
        sutTableHeader: 'SPECIALITY',
        sutTableHeader1: '% ALLOCATION',
        sutTableHeader2: 'SUTURES USED PER CASE',
        sutTableHeader3: 'USE RANGE',
        sutTableHeader4: 'WHAT IS YOUR CURRENT METHOD',
        sutTableHeader5: 'FOR STORING SUTURES?',
        sutButton: 'Save for later',
        allocationError1: '% allocation is required.',
        allocationError2: '% allocation should not be more than 100%.',
        allocationError3: '% allocation accepts only integer.',
        allocationError4: '% allocation accepts only integer greater than zero.',
        suturesPCaseError: 'Sutures used per case is required.',
        useRangeError: 'Use range is required.',
        sutStoreMethodError: 'Method for storing sutures required.'
    },
    preferenceCard_module: {
        accuracy: 'Accuracy',
        cost: 'Cost',
        usage: 'Usage',
        productCode: 'PRODUCT CODE',
        productName: 'PRODUCT NAME',
        quantity: 'QUANTITY',
        averageQuantityOfPeers: 'AVG. QTY. OF PEERS',
        unitPrice: 'UNIT PRICE',
        totalOrdered: ' Total Picked',
        totalReturned: 'TOTAL RETURNED',
        returnRate: 'Return Rate',
        rowsPerPage: 3,
        mtd: 'MTD',
        ytd: 'YTD',
        ttm: 'TTM',
        dataRange: 'Date Range',
        prefDefaultSortField: 'quantity',
        prefDefaultSortDirection: 'DESC',
        averageSutureCost: 'Average suture cost',
        averageSutureCostPeers: 'Average suture cost for peers',
        averageSutureUsagePeers: 'Average suture usage for peers',
        averageSutureUsage: 'Average suture usage',
        AverageAccuracyPrecedure: 'Average accuracy for procedure',
        TotalUsedQty: 'Total used qty.',
        TotalPickedQty: 'Total picked qty.',
        TotalOrderedQty: 'Total ordered qty.'
    },
    controlTower_module: {
        deviceSerialNumber: 'Hardware Unit',
        StackNumber: 'Stack Number',
        Product: 'Product',
        Quantity: 'Quantity(EA)',
        PAR: 'PAR',
        parField: 'par',
        deviceCap: 'DEVICE',
        orderNumberCap: 'ORDER NUMBER',
        productCodeCap: 'PRODUCT CODE',
        dateCap: 'DATE',
        quantityCap: 'QUANTITY',
        quantitySmall: 'quantity',
        smartShelf: 'SMART_SHELF',
        sutureHub: 'SUTURE_HUB',
        kiosk: 'KIOSK',
        averageDailyUSAGE: 'Average Daily Usage',
        averageDailyUsageField: 'averageDailyUsage',
        additionalInventoryShelf: 'Additional Inventory (Shelf)',
        additionalInventoryHub: 'Additional Inventory (Hub)',
        additionalInventoryKiosk: 'Additional Inventory (Kiosk)',
        inventoryLevels: 'Inventory Levels',
        device: 'Device',
        stockouts: 'STOCKOUTS',
        medium: 'MEDIUM',
        low: 'LOW',
        percentageFull: 'Percentage FULL',
        percentFull: 'Percent Full',
        mostRecentOrder: 'Most Recent Order',
        product_code: 'productCode',
        date: 'date',
        unit: 'unit',
        depensed_restocked: 'Dispensed & Restocked',
        schedule_code: 'Schedule Code',
        Primary_Surgeon: ' Primary Surgeon',
        date_header: 'Date',
        capacity: 'Percent Full',
        tableEmptyLine: 'No data available',
        productCode: 'Product Code',
        quantityEA: 'Quantity (EA)',
        filterDaysConstant: 14,
        homepageTransitTileHeader: 'Products  in Transit or Processing',
        homepageTransitTileEmpty: 'There are currently no products in transit or processing.',
        stockoutTooltipMsg: 'Selecting <b>STOCKOUTS</b> will show products with no inventory on-hand.',
        lowTooltipMsg: 'Selecting <b>LOW</b> will show products with inventory levels below PAR.',
        mediumTooltipMsgG: 'Selecting <b>MEDIUM</b> will show products with inventory levels that are approaching PAR.',
        highTooltipMsgG: 'Selecting <b>HIGH</b> will show products with sufficient inventory levels.',
        inventoryVal: 'Inventory Value',
        graphEmptyLine: 'No data available',
        perPage1: 10,
        perPage2: 25,
        perPage3: 50,
        values: 'values',
        total: 'total',
        monoTone: 'monotone',
        boxesOrdered: 'Boxes Ordered',
        cumilativeBox: 'Cummulative % of Boxes Ordered',
        timePeriod: 'Time Period',
        mtd: 'Month-to-Date (MTD)',
        ytd: 'Year-to-Date (YTD)',
        ttm: 'Trailing-12-Months (TTM)',
        dateRange: 'Date Range',
        mtdShort: 'MTD',
        ytdShort: 'YTD',
        ttmShort: 'TTM',
        trailing30Days: 'Trailing-30-Days',
        deviceName: 'Hardware Unit',
        inventoryLevelFootnote: '* When a single product resides in more than one location within the device, Average Daily Usage, Additional Inventory, and Inventory Value will show as the same between these locations and are representative of the whole device, rather than of each location.',
        aduParMessage: 'Average Daily Usage /PAR updated',
        smartRoom: 'SMART_ROOM',
    },
    financialOverview_module: {
        budgetLabel: 'Budget:',
        actualLabel: 'Actual:',
        dollarsLabel: 'Dollars:',
        percentLabel: 'Percent:',
        greenIndicator: 'GREEN',
        redIndicator: 'RED',
        upDirection: 'UP',
        downDirection: 'DOWN',
    },
    productDetails_module: {
        surgeon: 'SURGEON',
        procedureName: 'PROCEDURE NAME',
        procedureFrequency: 'PROCEDURE FREQUENCY',
        totalPickedQty: 'TOTAL PICKED QTY.',
        totalUsedQty: 'TOTAL USED QTY.',
        totalReturnedQty: 'TOTAL RETURNED QTY.',
        procedureDetailRowsPerPage1: 10,
        procedureDetailRowsPerPage2: 25,
        procedureDetailRowsPerPage3: 50,
        mtd: 'MTD',
        ytd: 'YTD',
        ttm: 'TTM',
        defaultPageNo: 0,
        defaultSortField: 'TOTAL PICKED QTY.',
        defaultSortDir: 'DESC',
    },
    materialManagement_module: {
        inventoryTileName: 'Inventory',
        inventoryStackCountTileName: 'Inventory - Stack Count',
        inventoryValueTileName: 'Inventory - Inventory Value',
        boxesOrderedReplenishedTileName: 'Boxes Ordered vs. Replenished',
        dispensedRestockedTileName: 'Dispensed & Restocked',
        dispensedRestockedLabel: 'Dispensed & Restocked',
        dispensedRestockApiString: 'Dispensed%20%26%20Restocked',
        restockedRatioCallout: 'Restocked Ratio',
        replenishedRatioCallout: 'Replenished Ratio',
        picking: 'PICKING',
        readyForRetrieval: 'READY FOR RETRIEVAL',
        readyForManualPick: 'READY FOR MANUAL PICK',
        retrieved: 'RETRIEVED',
        closed: 'CLOSED',
        error: 'ERROR',
        readyForPicking: 'READY FOR PICK',
        smartRoomRetrieved: 'SMART ROOM RETRIEVED',
        kioskShelfRetrieved: 'KIOSK AND SHELF RETRIEVED',
        kioskSmartRoomRetrieved: 'KIOSK AND SMART ROOM RETRIEVED',
        shelfSmartRoomRetrieved: 'SHELF AND SMART ROOM RETRIEVED',
        pickingSummaryTotalCases: 'Total Cases',
        pickingSummaryCompletedCases: 'Completed',
        pickingSummaryTab1: 'WIP',
        pickingSummaryTab2: 'Completed',
        coverage: 'Coverage',
        kiosk: 'Kiosk',
        shelf: 'Shelf',
        partial: 'Partial',
        pickingTile: 'Picking',
        pickingPercentage: 'Picking Summary',
        coverageKiosk: 'Kiosk',
        coverageKioskCount: 'Total Kiosk',
        coverageShelf: 'Shelf',
        coverageShelfCount: 'Total Shelf',
        coveragePartial: 'Partial',
        coveragePartialCount: 'Total Partial',
        ASC: 'ASC',
        tomorrow: 'tomorrow',
        interrupted: 'INTERRUPTED',
        pickingTileTableField1: 'origin',
        pickingTileTableTitle1: 'Device',
        pickingTileTableField2: 'scheduleCode',
        pickingTileTableTitle2: 'Case No',
        pickingTileTableField3: 'status',
        pickingTileTableTitle3: 'Status',
        pickingTileTableField4: 'quantity',
        pickingTileTableTitle4: 'Qty.',
        noCaseFound: 'No cases found!',
        todayProgressTileName: 'Todays Progress Tracker',
        kioskRetrieved: 'KIOSK RETRIEVED',
        shelfRetrieved: 'SHELF RETRIEVED',
        printList: 'Print List',
        customDateRange: 'Custom Date Range',
        bar1Label: 'Ordered',
        bar2Label: 'Replenished',
        retrieveFromKiosk: 'RETRIEVE FROM KIOSK',
        retrieveFromShelf: 'RETRIEVE FROM SHELF',
        kioskInitiated: 'KIOSK INITIATED',
        partiallyRetrieved: 'PARTIALLY RETRIEVED',
        retrieveFromSmartRoom: 'RETRIEVE FROM SMART ROOM',
        retrieveFromMultipleDevices: 'RETRIEVE FROM MULTIPLE DEVICES',
        coverageSmartRoomCount: 'Total SmartRoom',
        coverageSmartRoom: 'Smart Room',
        smartRoom: 'SMART_ROOM',
    },
    clinical_module: {
        preferenceCardAccuracyLabel: 'Preference Card Accuracy',
        orScanningLabel: 'OR Scanning',
        productUsageReturnsLabel: 'Product Usage & Returns',
        financialOverviewLabel: 'Financial Overview',
        productUsageReturnsBar1Label: 'Used Qty. (Scheduled Cases)',
        productUsageReturnsBar2Label: 'Used Qty. (Add-on Cases)',
        productUsageReturnsBar3Label: 'Returned Qty.',
        productUsageReturnsLineLabel: 'Product Usage & Returns',
        productUsageReturnsBar1LegendLabel: 'Used Qty. (Scheduled Cases)',
        productUsageReturnsBar2LegendLabel: 'Used Qty. (Add-on Cases)',
        productUsageReturnsBar3LegendLabel: 'Returned Qty.',
        productUsageReturnsLineLegendLabel: 'Return Rate',
        orScanningBar1Label: 'Nurse-Scanned',
        orScanningBar2Label: 'Scanned w/o Code',
        orScanningBar3Label: 'Auto-Closed',
        orScanningLineLabel: 'OR Scanning Accuracy',
        orScanningBar1LegendLabel: 'Nurse-Scanned',
        orScanningBar2LegendLabel: 'Auto-Closed',
        orScanningBar3LegendLabel: 'Scanned w/o Code',
        orScanningLineLegendLabel: 'Scanning Compliance',
    },
    orScanningCardOverview_module: {
        orScanningCardOverviewRowsPerPage1: 10,
        orScanningCardOverviewRowsPerPage2: 25,
        orScanningCardOverviewRowsPerPage3: 50,
        defaultPageNo: 0,
    },
    preferenceCardOverview_module: {
        highCostLabel: 'High Cost',
        highCostLink: '/insights/preference-card/overview/high-cost',
        inaccurateLabel: 'Inaccurate',
        inaccurateLink: '/insights/preference-card/overview/inaccurate',
        inactiveLabel: 'Inactive',
        inactiveLink: '/insights/preference-card/overview/inactive',
        recenltyModifiedLabel: 'Recenlty Modified',
        recenltyModifiedLink: '/insights/preference-card/overview/recently-modified',
        rarelyModifiedLabel: 'Rarely Modified',
        rarelyModifiedLink: '/insights/preference-card/overview/rarely-modified',
        totalPreferenceCardsLabel: 'Total Preference Cards',
        totalPreferenceCardsLink: '/insights/preference-card/overview',
        preferenceCardOverviewRowsPerPage1: 10,
        preferenceCardOverviewRowsPerPage2: 25,
        preferenceCardOverviewRowsPerPage3: 50,
        defaultPageNo: 0,
    },
    productUsageOverview_module: {
        highCostLabel: 'High Cost',
        highCostHighReturnsLink: '/insights/inventory/overview/high-cost-high-returns',
        highCostLowPicksLabel: 'Low Use',
        highCostLowPicksLink: '/insights/inventory/overview/high-cost-low-picks',
        inactiveLabel: 'Inactive',
        inactiveLink: '/insights/inventory/overview/inactive',
        usedByFewLabel: 'Used by Few',
        usedByFewLink: '/insights/inventory/overview/rarely-used',
        highReturnsAllLabel: 'High Returns',
        highReturnsAllLink: '/insights/inventory/overview/high-returns-all',
        totalProductsLabel: 'Total Products',
        totalProductsLink: '/insights/inventory/overview',
        inventoryOverviewRowsPerPage1: 10,
        inventoryOverviewRowsPerPage2: 25,
        inventoryOverviewRowsPerPage3: 50,
        defaultPageNo: 0,
    },
    role: {
        superAdmin: 'Super Admin',
        adminMaintenance: 'Admin Maintenance',
        adminPeriOp: 'Admin Peri Op',
        circulatingNurse: 'Circulating Nurse',
        hospitalExec: 'Hospital Exec',
        hospitalIT: 'Hospital IT',
        materialsHandler: 'Materials Handler',
        materialsManager: 'Materials Manager',
        nurseManager: 'Nurse Manager',
        adminSupplyChain: 'Admin Supply Chain',
        materialsAdmin: 'Materials Admin',
        clinicalAdmin: 'Clinical Admin',
    },
    aitaAdvisor_module: {
        advisorSummary: 'advisorSummary',
        preferenceCardSuggestion: 'preference-card-suggestion',
        takeAction: 'Take Action',
        preferenceCardSuggestions: 'Preference Card Suggestions',
        portfolioSuggestions: 'Portfolio Suggestions',
        portfolioSuggestionsParam: 'Portfolio%20Suggestion',
        procedureSuggestions: 'Procedure Suggestions',
        inventorySuggestions: 'Inventory Suggestions',
        inventorySuggestionsParam: 'Inventory%20Suggestion',
        available: 'AVAILABLE',
        completed: 'COMPLETED',
        rejected: 'REJECTED',
        postponed: 'POSTPONED',
        inprogress: 'INPROGRESS',
        successResponse: '2001',
        lastUpdateDate: '(Last Updated on',
        lastUpdateDateEnd: ')',
        pcardEmpty: 'AITA is collecting Data. Suggestions coming soon!',
        all: 'all',
        impact: 'impact',
        procedureSuggestion: 'procedure-suggestion',
        procedureSuggestionsParam: 'Procedure%20Suggestion',
        speciality: 'speciality',
        numberOfSurgeons: 'numberOfSurgeons',
        procedureName: 'procedureName',
        numberOfProcedures: 'numberOfProcedures',
        numberOfCommonSkusUsed: 'numberOfCommonSkusUsed',
        numberOfSkusUsed: 'numberOfSkusUsed',
        commonSkus: 'commonSkus',
        commonality: 'commonality',
        status: 'status',
        preferenceCardSuggestionField: 'Pcard Suggestion',
        portfolioSuggestion: 'portfolio-suggestion',
        inventorySuggestion: 'inventory-suggestion',
        pcardSuggestionsParam: 'PCard%20Suggestion',
        skusUsed: 'skusUsed',
        takeActionPCardLabel: 'Please select an action to take for preference card:',
        takeActionLabel: 'Please select an action to take to:',
        takeActionProcedure: 'Please select an action to take for procedure:',
        requestDetails: 'Request Details',
        postpone: 'Postpone',
        reject: 'Reject',
        requestDetailsConfirmation: 'Thank you. A member of the AITA team will reach out with additional details on this suggestion.',
        approveActionLabel: 'You are about to approve an action to preference card:',
        allApproveActionLabel: 'You are about to approve an action to:',
        procedureApproveActionLabel: 'You are about to approve an action to consolidate SKUs for procedure:',
        confirmationLabel: 'Thank you for confirming change to preference card:',
        allConfirmationLabel: 'Thank you for confirming the following change:',
        procedureConfirmationLabel: 'Thank you for choosing to consolidate SKUs for:',
        emailLabel: 'The AITA team will follow up to coordinate next steps. You will receive an email shortly.',
        postponeLabel: 'Select when to postpone for:',
        actionPostponed: 'Action has been postponed',
        rejectReason: 'Please provide reason for rejecting suggestions:',
        rejectedLabel: 'Suggestion has been rejected.',
    }
};

