import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdamConf } from '@app/app.config';
import { RecaptchaModule } from 'ng-recaptcha-2';

@Component({
  selector: 'app-captcha',
  standalone: true,
  imports: [RecaptchaModule],
  templateUrl: './captcha.component.html',
  styleUrl: './captcha.component.scss',
})
export class CaptchaComponent implements OnInit {
  @Output() captchaResponseHandler = new EventEmitter<string>();
  public adamLabels = AdamConf;

  constructor() { }

  ngOnInit() { }

  resolved(captchaResponse: string): void {
    this.captchaResponseHandler.emit(captchaResponse);
  }

  errored(): void {
    console.warn(`reCAPTCHA error encountered`);
    this.captchaResponseHandler.emit(null);
  }

}
