<div *ngIf="showDialog">
  <div class="custom-dialog-width">
    <p-dialog [(visible)]="showDialog" showHeader="false" [modal]="true" [responsive]="true" [minY]="70"
      [baseZIndex]="10000" header="Application Error" styleClass="customDialog">
      <div class="text-right">
        <em class="material-icons close-icon" (click)="showDialog=false">close</em>
      </div>
      <div class="padding-custom">
        Status : {{ errorDialog.status }} {{ errorDialog.statusText }} <br>
        Url : {{ errorDialog.url }}<br>
        Details : {{ errorDialog.error.message }}<br>
      </div>
      <p-footer>
        <button class="button-primary-large button-width" (click)="display()">Go to Dashboard</button>
      </p-footer>
    </p-dialog>
  </div>
</div>