import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { MaterialsManagementService } from '../../materials-management/materials-management.service';
import { SummaryDataApiResponse } from '../../models/materials-management-dashboard.model';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';
import { AdamConf } from '@app/app.config';
import { MatCardModule } from '@angular/material/card';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-core-dashboard-materials-management-summary-tile',
  standalone: true,
  imports: [MatCardModule],
  templateUrl: './summary-tile.component.html',
  styleUrls: ['./summary-tile.component.scss']
})
export class SummaryTileComponent implements OnInit, OnDestroy {

  @Input() displayTile: string;
  public adamLabels = AdamConf;
  private dataSubscription$: Subscription;
  private ticker$: Subscription;
  public label: string;
  public labelValue: string;
  public firstLabel: string;
  public firstValue: string;
  public secondLabel: string;
  public secondValue: string;
  public imageName: string;
  public imageHyperlink: string;
  public value: number;

  constructor(
    private readonly baseService: MaterialsManagementService,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.getDataForSummaryTile(this.displayTile);
    this.setAutoRefresh();
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser && currentUser.isAutoRefreshEnabled === true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval ?? 60000; // Default to 60 seconds if undefined
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe(() => {
        this.logger.log('Refreshing summary tile data');
        this.getDataForSummaryTile(this.displayTile);
      });
    }
  }

  public getDataForSummaryTile(tileName: string): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    this.dataSubscription$ = this.baseService.getSummaryDataFilters(tileName).subscribe((dataItems) => {
      if (dataItems.responseCode === '2001') {
        if (dataItems.responseData.keyValueItems && dataItems.responseData.keyValueItems.length > 0) {
          this.setSummaryData(dataItems);
          this.cdr.detectChanges(); // Manually trigger change detection
        }
      } else {
        console.error('Error fetching data:', dataItems);
      }
    }, (error) => {
      console.error('Subscription error:', error);
    });
  }

  public setSummaryData(dataItems: SummaryDataApiResponse): void {
    const firstCase = 1;
    const secondCase = 2;
    const thirdCase = 3;
    dataItems.responseData.keyValueItems.forEach((tileData) => {
      switch (tileData.order) {
        case firstCase:
          this.label = tileData.key;
          this.labelValue = (parseFloat(tileData.value)).toFixed() + '%';
          this.baseService.setTodaysProgressTrackerData({
            tileName: dataItems.responseData.area,
            pieValue: (parseFloat(tileData.value)).toFixed()
          });
          break;

        case secondCase:
          this.firstLabel = tileData.key;
          this.firstValue = tileData.value;
          break;

        case thirdCase:
          this.secondLabel = tileData.key;
          this.secondValue = tileData.value;
          break;

        default: break;
      }
    });
  }

  public navigateToOtherPage(label: string): void {
    if (label === 'Replenishment Summary') {
      this.router.navigate(['/inventories/order-management/history']);
    } else if (label === 'Restocking Summary') {
      this.router.navigate(['/control-tower/dispensed-restocked']);
    } else if (label === 'Ordering Summary') {
      this.router.navigate(['/inventories/order-management/cart']);
    } else {
      this.ngOnDestroy();
    }
  }

  public ngOnDestroy(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }
}
