import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { ClinicalService } from '../clinical.service';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { TileFilterInfo } from 'src/app/core/dashboard/models/clinical-dashboard-model.model';
import { AdamConf } from '@app/app.config';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';
import {LineChartClinicalComponent} from '../tile-graphs/line-chart-clinical/line-chart-clinical.component'

import { TileMagnifierComponent } from '../tile-magnifier/tile-magnifier.component'
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-preference-card-accuracy-tile',
  templateUrl: './preference-card-accuracy-tile.component.html',
  styleUrls: ['./preference-card-accuracy-tile.component.scss'],
  standalone: true,
  imports: [LineChartClinicalComponent, TileMagnifierComponent,CommonModule]
})
export class PreferenceCardAccuracyTileComponent implements OnInit, OnDestroy {

  public isDisplayFilter = false;
  public filterConfig: any;
  public adamLabels: any;
  public mtd: number;
  public ytd: number;
  public nurseScanned: string;
  public totalCases: number;
  public lineChartData: any;
  public chartMetaData: any;
  private filterSubscription$: Subscription;
  private dataSubscription$: Subscription;
  private ticker$: Subscription;
  public preferenceCardOverviewParams: TileFilterInfo;
  @Input() showMagnifiedViewPopupData: any;
  @Output() showMagnifiedPopup = new EventEmitter<string[]>();

  constructor(
    private readonly clinicalService: ClinicalService,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService
  ) {
    this.adamLabels = AdamConf;
    this.chartMetaData = {
      type: 'preference-card-accuracy-tile',
      xAxis: 'date',
      yAxis: 'percentage',
      margin: {
        top: 20,
        right: 20,
        bottom: 50,
        left: 45
      }
    };
  }

  public ngOnInit(): void {
    this.loadPrefernceCardAccuracyTile();
    this.setAutoRefresh();
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Preference Card Accuracy tile data');
        this.loadPrefernceCardAccuracyTile();
      });
    }
  }

  private resetPreferenceCardTileSubscriptions(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.filterSubscription$) {
      this.filterSubscription$.unsubscribe();
    }
  }

  public ngOnDestroy(): void {
    this.resetPreferenceCardTileSubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private loadPrefernceCardAccuracyTile(): void {
    this.resetPreferenceCardTileSubscriptions();
    this.filterSubscription$ = this.clinicalService.preferenceCardAccuracyFilter$
      .subscribe((data) => {
        if (data) {
          this.preferenceCardOverviewParams = {
            tileName: 'Preference Card Accuracy',
            tileFilters: data
          };
          this.getDataByFilters();
        }
      });
  }

  private getDataByFilters(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }

    this.dataSubscription$ = this.clinicalService.getClinicalTileData(this.preferenceCardOverviewParams)
      .subscribe((dataItems) => {
        if (dataItems.responseCode === '2001' && dataItems.responseData.tileName) {
          this.lineChartData = dataItems.responseData;
          if (dataItems.responseData.calloutData && dataItems.responseData.calloutData.length > 0) {
            dataItems.responseData.calloutData.forEach((tileData) => {
              switch (tileData.label) {
                case 'MTD':
                  this.mtd = tileData.value;
                  break;
                case 'YTD':
                  this.ytd = tileData.value;
                  break;
                case 'Nurse-Scanned Cases:':
                  this.nurseScanned = tileData.firstDetailValue;
                  break;
                case 'of Total Cases':
                  this.totalCases = tileData.value;
                  break;
              }
            });
          }
        }
      });
  }

  public displayFilter(): void {
    this.isDisplayFilter = true;
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      tile: 'Preference Card Accuracy'
    };
    this.clinicalService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile =
      (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? this.adamLabels.clinical_module.preferenceCardAccuracyLabel : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

  public navigateToPreferenceCardOverview(): void {
    this.router.navigate(['/insights/preference-card/overview/', 'all']);
  }

}
