<div class="privacy-policy">
    <div class="row">
        <div class="offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-8 col-xl-6 col-md-9 col-sm-8 col-xs-12">
            <header class="header1 black">Privacy policy</header>
            <p>Medical Devices Business Services, Inc. cares about your privacy and wants you to be familiar with
                how we collect, use, and disclose information. This Privacy Policy describes our practices in
                connection with information that we or our service providers collect through the website or
                application (hereinafter the “Service”) operated and controlled by us from which you are accessing
                this Privacy Policy. By providing personal information to us or by using the Service, you
                acknowledge that you have read and understand this Privacy Policy.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
<p class="black f-18">Use by minors</p>
<p class="h">&nbsp;</p>
<p class="h">&nbsp;</p>
<p>The Service is not directed to individuals under the age of 18, and we request that these individuals
                not provide personal information through the Service. If your child has submitted Personal
                Information and you would like to request that such Personal Information be removed, please contact
                us as explained below under Contacting Us.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p class="black f-18">Information collection</p>
            <p class="h">&nbsp;</p>
            <p class="h">&nbsp;</p>
            <p>We may ask you to submit personal information in order for you to benefit from certain features (such
                as newsletter subscriptions, tips/pointers, or order processing) or to participate in a particular
                activity (such as sweepstakes or other promotions). You will be informed what information is
                required and what information is optional.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p>We may combine the information you submit with other information we have collected from you, whether
                on‑ or offline, including, for example, your purchase history. We may also combine it with
                information we receive about you from other sources, such as other Johnson & Johnson Affiliates,
                publicly available information sources (including information from your publicly available social
                media profiles), and other third-party information providers.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p>If you submit any personal information relating to another individual to us, you represent that you
                have the authority to do so and to permit us to use the information in accordance with this Privacy
                Policy.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p class="black f-18">Sensitive information</p>
            <p class="h">&nbsp;</p>
            <p class="h">&nbsp;</p>
            <p>Unless we specifically request or invite it, we ask that you not send us, and you not disclose, any
                sensitive personal information (e.g., Social Security numbers, information related to racial or
                ethnic origin, political opinions, religion or philosophical beliefs, health, sex life or sexual
                orientation, criminal background, or trade union membership, or biometric or genetic data for the
                purpose of uniquely identifying an individual) on or through the Service or otherwise to us.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p class="black f-18">Automatic information collection and use</p>
            <p class="h">&nbsp;</p>
            <p class="h">&nbsp;</p>
            <p>We and our service providers may automatically collect and use information in the following ways as
                you navigate around the Service:</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p><span class="black f-14">Through your browser:</span> Certain information is collected by most browsers, such as your Media
                Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating system
                name and version, and Internet browser type and version. We may collect similar information,
                such as your device type and identifier, if you access the Service through a mobile device. We
                use this information to ensure that the Service functions properly.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p><span class="black f-14">Using cookies:</span> Cookies are pieces of information stored directly on the computer you are
                using. Cookies allow us to collect information such as browser type, time spent on the
                Service, pages visited, and language preferences. We and our service providers use the
                information for security purposes, to facilitate navigation, display information more effectively,
                and to personalize your experience while using the Service. We also use cookies to recognize
                your computer or device, which makes your use of the Service easier, such as to remember what is in
                your shopping cart. In addition, we use cookies to gather statistical information about
                Service usage in order to continually improve its design and functionality, understand how
                individuals use it, and to assist us with resolving questions regarding it. Cookies further
                allow us to select which of our advertisements or offers are most likely to appeal to you and
                display them while you are on the Service. We may also use cookies in online advertising to
                track consumer responses to our advertisements.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p>You can refuse to accept these cookies by following your browser’s instructions; however, if you do
                not accept them, you may experience some inconvenience in your use of the Service. You may
                also not receive advertising or other offers from us that are relevant to your interests and
                needs. To learn more about cookies, please visit <a rel="noopener noreferrer"
                    href="http://www.allaboutcookies.org" target="_blank">http://www.allaboutcookies.org</a>.</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
            <p><span class="black f-14">Using Flash cookies:</span> Our use of Adobe Flash technology (including Flash Local Stored Objects
                (“Flash LSOs”)) allows us to, among other things, serve you with more tailored information,
                facilitate your ongoing access to and use of the Service, and collect and store information about
                your use of the Service. If you do not want Flash LSOs stored on your computer, you can adjust
                the settings of your Flash player to block Flash LSO storage using the tools contained in the
                Website Storage Settings Panel. You can also control Flash LSOs by going to the Global Storage
                Settings Panel and following the instructions. Please note that setting the Flash Player to
                restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of some Flash
                applications.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p><span class="black f-14">Using pixel tags, web beacons, clear GIFs, or other similar technologies:</span> These may be used in
                connection with some Service pages and HTML‑formatted e‑mail messages to, among other things, track
                the actions of users and e‑mail recipients, measure the success of our marketing campaigns, and
                compile statistics about Service usage.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p><span class="black f-14">Interest-based advertising:</span> We may use third-party advertising companies to serve
                advertisements regarding goods and services that may be of interest to you when you access and use
                the Service and other online services, based on information relating to your access to and use of
                the Service and other online services on any of your devices. To do so, these companies may
                place or recognize a unique cookie on your browser (including through the use of pixel tags).
                They may also use these technologies, along with information they collect about your online use, to
                recognize you across the devices you use, such as a mobile phone and a laptop.</p>
                <p class="h">&nbsp;</p>
                <p class="h">&nbsp;</p>
            <p>If you would like more information about this practice, and to learn how to opt out of it in desktop
                and mobile browsers on the particular device on which you are accessing this Privacy Policy, please
                visit <a rel="noopener noreferrer" href="http://optout.aboutads.info/#/" target="_blank">http://optout.aboutads.info/#/</a> and <a
                    rel="noopener noreferrer" href="http://optout.networkadvertising.org/#/" target="_blank">http://optout.networkadvertising.org/#/</a>.
                You may download the AppChoices app at <a rel="noopener noreferrer"
                    href="http://www.aboutads.info/appchoices" target="_blank">
                    www.aboutads.info/appchoices</a> to opt out in mobile apps.</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p><span class="black f-14">IP address:</span> Your IP address is a number that is automatically assigned to your computer by your
                        Internet Service Provider. An IP address is identified and logged automatically in our server
                        log files whenever a user visits the Service, along with the time of the visit and the pages
                        visited. Collecting IP addresses is standard practice and is done automatically by many online
                        services. We use IP addresses for purposes such as calculating Service usage levels,
                        diagnosing server problems, and administering the Service. We may also derive your approximate
                        location from your IP address.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p><span class="black f-14">Device Information:</span> We may collect information about your mobile device, such as a unique
                        device identifier, to understand how you use the Service.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p class="black f-18">How we use and disclose information</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p>We use and disclose information you provide to us as described to you at the point of
                        collection. Please see the section entitled “Choices and Access,” below, to learn how you may
                        opt out of certain of our uses and disclosures.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p>Where required by applicable law, we will obtain your consent to our use of your personal information
                        at the point of information collection. We may also use information from or about you as
                        necessary to perform a contract, to comply with a legal obligation (for example, due to our
                        pharmacovigilance obligations), or for our legitimate business interests. Our legitimate
                        business interests may include any or all of the following:</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <ul>
                        <li>
                            <p>to respond to your inquiries and fulfill your requests, such as to send you documents you request or
                             e‑mail alerts;</p>
                        </li>
                        <li>
                            <p>to send you important information regarding our relationship with you or regarding the Service,
                                changes to our terms, conditions, and policies and/or other administrative information; and</p>
                        </li>
                        <li>
                            <p>for other business purposes, such as data analysis, audits, developing new products, enhancing our
                                website, improving our products and services, identifying Service usage trends, better understanding
                                your interests so that we can serve you better, personalizing your experience on the Service by
                                presenting products and offers tailored to you, and determining the effectiveness of our promotional
                                campaigns.</p>
                        </li>
                    </ul>
                    <p>We also disclose information collected through the Service:</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <ul>
                        <li>
                            <p>to our affiliates for the purposes described in this Privacy Policy. A list of our affiliates
                                is available at <a rel="noopener noreferrer"
                                    href="http://www.investor.jnj.com/governance/sec‑filings.cfm" target="_blank">http://www.investor.jnj.com/governance/sec‑filings.cfm</a>
                                (click on the link for Form 10K, Exhibit 21, under “SEC Filings”). Medical Devices Business
                                Services, Inc. is the party responsible for the management of the jointly‑used Personal Information;
                            </p>
                        </li>
                        <p class="h">&nbsp;</p>
                        <li>
                            <p>to our third party partners with whom we offer a co‑branded or co‑marketed promotion;</p>
                        </li>
                        <p class="h">&nbsp;</p>
                        <li>
                            <p>to our third party service providers who provide services such as website hosting and moderating,
                        mobile application hosting, data analysis, payment processing, order fulfillment, infrastructure
                        provision, IT services, customer service, e‑mail and direct mail delivery services, auditing, and
                        other services, in order to enable them to provide services; and</p>
                        </li>
                        <p class="h">&nbsp;</p>
                        <li>
                            <p>as permitted by applicable law, to a third party in the event of any reorganization, merger, sale,
                        joint venture, assignment, transfer, or other disposition of all or any portion of our business,
                        assets, or stock (including in connection with any bankruptcy or similar proceedings).</p>
                        </li>
                    </ul>
                    <p>In addition, we may use and disclose your information as we believe to be necessary or
                    appropriate: (a) to comply with legal process or applicable law, which may include laws
                    outside your country of residence; (b) as permitted by applicable law to respond to requests from
                    public and government authorities, which may include authorities outside your country of residence;
                    (c) to enforce our terms and conditions; and (d) to protect our rights, privacy, safety, or
                    property, and/or that of our affiliates, you, or others. We may also use and disclose your
                    information in other ways, after obtaining your consent to do so.</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p>We may use and disclose information we collect automatically as described above, under “Automatic
                        Information Collection and Use.”</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p>In addition, where allowed by applicable law, we may use and disclose information that is not in
                        personally identifiable form for any purpose. If we combine information that is not in
                        personally identifiable form with information that is identifiable (such as combining your name with
                        your geographical location), we will treat the combined information as personal information as long
                        as it is combined.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p class="black f-18">Choices and access</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p class="black f-16">Your choices regarding our use and disclosure of your personal information</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p>We give you choices regarding our use and disclosure of your personal information for marketing
                        purposes. You may opt out from:</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p><span class="black f-14">Receiving marketing communications from us:</span> If you no longer want to receive marketing
                        communications from us on a going‑forward basis, you may opt out of receiving them by contacting us
                        via email at <span [innerHTML]="email1"></span>. In
                        your request to us, please provide your name, identify the form(s) of marketing communications that
                        you no longer wish to receive, and include the address(es) to which it/they are sent. For
                        example, if you no longer wish to receive marketing e‑mails or direct mail from us, tell us that,
                        and provide your name and e‑mail or postal address. In addition, you may opt out of
                        receiving marketing e‑mails from us by following the unsubscribe instructions provided in any such
                        message.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p><span class="black f-14">Receiving reminders from us:</span> If you no longer want to receive medical reminders from us on a
                        going‑forward basis, you may opt out of receiving them by contacting us via email at <span [innerHTML]="email2"></span>. In
                        your response to us, please provide your name and the e‑mail address or phone number at which you
                        receive reminders from us.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p><span class="black f-14">Our sharing of your personal information with affiliates and third‑party partners:</span> If you
                        previously opted in to receiving marketing communications from our affiliates or third party
                        partners, you may opt out of our sharing of your personal information with those parties for their
                        direct marketing purposes on a going‑forward basis by contacting us via email at <span [innerHTML]="email3"></span>. In
                        your communication to us, please state that we should no longer share your personal information with
                        our affiliates and/or third‑party partners for their marketing purposes, and include your name and
                        e‑mail address.</p>
                    <p>We will seek to comply with your request(s) as soon as reasonably practicable. Please note that
                        if you opt out as described above, we will not be able to remove your personal information from the
                        databases of our affiliates with which we have already shared your information (i.e., as of the date
                        that we implement your opt‑out request). Please also note that if you opt out of receiving
                        marketing‑related messages from us, we may still send you important transactional and administrative
                        messages, from which you cannot opt out.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p class="black f-18">How you can access, change, or delete your personal information</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p>If you would like to review, correct, update, restrict, or delete your personal information, or if
                        you would like to request to receive an electronic copy of your personal information for purposes of
                        transmitting it to another company (to the extent these rights are provided to you by applicable
                        law), please contact us via email at <span [innerHTML]="email1"></span>. We
                        will respond to your request as soon as reasonably practicable and no later than one month after
                        receipt. If circumstances cause any delay in our response, you will be promptly notified and
                        provided a date for our response.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                    <p class="black f-18">Cross–border transfer</p>
                    <p class="h">&nbsp;</p>
                    <p class="h">&nbsp;</p>
                    <p>Your personal information may be stored and processed in any country where we have facilities or
                        service providers, and by using our Service or by providing consent to us (where required by law),
                        you agree to the transfer of information to countries outside of your country of residence,
                        including to the United States, which may provide for different data protection rules than in your
                        country. Appropriate contractual and other measures are 
                        in place to protect personal information when it is transferred to our affiliates or third parties in other countries.</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="black f-18">Security</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p>We seek to use reasonable organizational, technical, and administrative measures designed to protect
                            personal information under our control. Unfortunately, no data transmission over the Internet
                            or data storage system can be guaranteed to be 100% secure. If you have reason to believe that
                            your interaction with us is no longer secure (for example, if you feel that the security of any
                            account you have with us has been compromised), please immediately notify us in accordance with the
                            “Contacting Us” section below.</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                        <p class="black f-18">Retention period</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p>We will retain your personal information for as long as needed or permitted in light of the
                            purpose(s) for which it was obtained. The criteria used to determine our retention periods
                            include: (i) the length of time we have an ongoing relationship with you and provide the
                            Service to you; (ii) whether there is a legal obligation to which we are subject; and (iii) whether
                            retention is advisable in light of our legal position (such as in regard to applicable statutes of
                            limitations, litigation, or regulatory investigations).</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
<p class="black f-18">Third party sites and services</p>
<p class="h">&nbsp;</p>
<p class="h">&nbsp;</p>
                        <p>This Privacy Policy does not address, and we are not responsible for, the privacy, information, or
                            other practices of any third parties, including any third party operating any site or online service
                            (including, without limitation, any application) that is available through this Service or to which
                            this Service contains a link. The availability of, or inclusion of a link to, any such site or
                            property on the Service does not imply endorsement of it by us or by our affiliates.</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                        <p class="black f-18">Contacting us</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p>Medical Devices Business Services, Inc., located at 700 Orthopaedic Drive., P.O. Box 988, Warsaw,
                            IN 46581, is the company responsible for collection, use, and disclosure of personal
                            information under this Privacy Policy.</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                        <p>If you have any questions about this Privacy Policy, please contact us via email at <span [innerHTML]="email1"></span>, or
                            please write to the following address:</p>
                            <p class="h">&nbsp;</p>
                            <p class="h">&nbsp;</p>
                        <p>Medical Devices Business Services, Inc.</p>
                        <p>Attention: Communications</p>
                        <p>700 Orthopaedic Drive</p>
                        <p>P.O. Box 988</p>
                        <p>Warsaw, IN 46581</p>
                        <p>(800) 473-3789</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>
                        <p class="black f-18">Updates to this privacy policy</p>
                        <p class="h">&nbsp;</p>
                        <p class="h">&nbsp;</p>

                        <p>We may change this Privacy Policy. Any changes to this Privacy Policy will become
                            effective when we post the revised Privacy Policy on the Service. Your use of the Service following
                            these changes means that you accept the revised Privacy Policy. We recommend that you
                            regularly review the Privacy Policy when you visit the Service. This policy was last updated on
                            March 2018.</p>
                    </div>
                </div>
            </div>
            