import { Component, OnInit } from '@angular/core';
import { PDialogService } from '../p-dialog.service';
import { Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-p-dialog',
  standalone: true,
  imports: [ DialogModule, CommonModule],
   styleUrl: './p-dialog.component.scss',
  templateUrl: './p-dialog.component.html'
})
export class PDialogComponent implements OnInit {
  public showDialog: boolean;
  public errorDialog: any;



  constructor(
    private readonly pDialogService: PDialogService,
    private readonly router: Router) { }

  public ngOnInit(): void {
    this.pDialogService.visibility.subscribe(data => {
      this.showDialog = data;
    });
    this.pDialogService.dialog.subscribe(data => {
      this.errorDialog = data;
    });
  }

  public display(): void {
    console.log("welcome");
    this.pDialogService.hide();
    this.router.navigate(['dashboard']);
  }
}
