import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { RequestAccessAPIResponse, RequestAccessParam, RoleListAPIResponse } from './models/request-access-model';

@Injectable({
  providedIn: 'root'
})
export class CoreService  {

  constructor(private readonly baseService: BaseService) {
  }

  getUserGroups(): Observable<RoleListAPIResponse> {
    return this.baseService.getViaParam('user-management/api/v1/public/users/roles', null);
  }

  postReqAccess(param: RequestAccessParam, userAction: string): Observable<RequestAccessAPIResponse> {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/users/accessRequests', param, {'user-action': userAction});
  }

  getApproveAccess() {
    return this.baseService.getViaParam('notification-management/api/v1/notify/alerts?role', null);
  }

  validateCaptchaResponse(captchaResponse: string){
    return this.baseService.getViaParam('user-management/api/v1/public/captcha/verification?googleApiResponse=' + captchaResponse, null);
  }

}
