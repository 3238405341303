import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

import{NotificationService} from'../notification.service';
import {
  AlertInfo,
  AlertOptionsInfo,
  CommonApiResponse,
  DateFilterParams,
  NotificationApiResponse,
  NotificationCountParams,
  
} from '../../models/header.model';
import { AdamConf } from '@app/app.config';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, FormBuilder, Validators,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InfiniteScrollComponent } from '@app/core/infinite-scroll/infinite-scroll.component';
import{AverageDailyUsagePipe} from 'src/app/shared/pipes/average-daily-usage.pipe'
import { CustomDatePipe } from '@app/shared/custom-date.pipe';
import { RouterModule } from '@angular/router';
import { NgClass } from '@angular/common'; 
import { MatIconModule } from '@angular/material/icon';
import { ExpireRecallSelectUnitComponent } from '@app/inventories/expire-recall-select-unit/expire-recall-select-unit.component';
@Component({
  selector: 'app-notification-list',
  standalone: true,
  imports: [ CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,InfiniteScrollComponent,AverageDailyUsagePipe,CustomDatePipe,RouterModule,NgClass,MatIconModule,ExpireRecallSelectUnitComponent ],
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss'
})
export class NotificationListComponent implements OnInit {
    adam_labels: any;
    notifications: AlertInfo[] = [];
    
    notificationCount: NotificationCountParams = {
      'total': 0,
      'alerts': 0
    };
    noResults: boolean = false;
    backEndValidationError: boolean = false;
    applicationError: boolean = false;
    tabClicked: boolean = false;
    dateFilter: DateFilterParams = {
      'week': true,
      'days': false,
      'all': false
    }
  
    alertFilter: any = {
      "all": true,
      "alert": false
    }
    alertOptions: AlertOptionsInfo = {
      "duration": 7,
      "notificationType": "All",
      "pageNo": -1,
      "pageSize": 20,
      "source": "All"
    }
    constructor(
      private readonly notificationService: NotificationService,
      private renderer: Renderer2,
      private readonly authService: AuthService) {
      this.adam_labels = AdamConf;
    }
  
    public ngOnInit(): void {
      this.getNotificationList(this.alertOptions);
      this.authService.initiateNotificationInfo();
    }
  
    public dateFilterClick(arg): void {
      this.dateFilter['week'] = false;
      this.dateFilter['days'] = false;
      this.dateFilter['all'] = false;
      this.dateFilter[arg] = true;
      if (arg == 'week') {
        this.alertOptions.duration = 7;
      }
      else if (arg == 'days') {
        this.alertOptions.duration = 30;
      }
      else if (arg == 'all') {
        this.alertOptions.duration = 0;
      }
      this.tabClicked = true;
      this.resetNotifications();
    }
  
    public alertFilterClick(arg): void {
      this.alertFilter['all'] = false;
      this.alertFilter['alert'] = false;
      this.alertFilter[arg] = true;
  
      this.alertOptions.notificationType = arg == 'all' ? 'All' : 'Alert';
      this.dateFilterClick('week');
    }
  
    private resetNotifications(): void {
      this.notifications = [];
      this.alertOptions.pageNo = -1;
      this.getNotificationList(this.alertOptions);
    }
  
    private getNotificationList(alertObj: AlertOptionsInfo): void {
      if (alertObj.pageNo == -1) {
        alertObj.pageNo = 0;
      }
      this.notificationService.getNotificationList(alertObj)
        .subscribe((response: NotificationApiResponse) => {
          this.tabClicked = false;
          if (response.responseCode === '2001') {
            if (response.responseData && response.responseData.alerts
              && response.responseData.alerts.length > 0) {
              this.getNotificationSuccessHandler(response);
            } else {
              this.getNotificationAlertHandler(response);
            }
          }
          else if (response.responseCode == "4002") {
            this.backEndValidationError = true;
          } else if (response.responseCode == "4001") {
            this.applicationError = true;
          }
        });
    }
  
    private getNotificationAlertHandler(response: NotificationApiResponse): void {
      if (response.responseData) {
        this.notificationCount.total = response.responseData.totalNotificationCount;
        this.notificationCount.alerts = response.responseData.totalAlertCount;
      }
      if (this.notifications.length == 0) {
        this.noResults = true;
      }
    }
  
    private getNotificationSuccessHandler(response: NotificationApiResponse): void {
      this.noResults = false;
      response.responseData.alerts = response.responseData.alerts.filter((alert) => {
        if (alert.body != null) {
          return alert
        }
      });
      this.notifications = this.notifications.concat(response.responseData.alerts);
      this.notifications.forEach((element) => {
        element.timeStamp = this.getTimeStampForNotification(element.notificationDate);
      });
      this.notificationCount.total = response.responseData.totalNotificationCount;
      this.notificationCount.alerts = response.responseData.totalAlertCount;
    }
  
    private getTimeStampForNotification(notDate: string): string {
      //EST
      let timeStamp = new Date().getTime() - new Date(notDate).getTime();
      let timeStampDays = Math.floor(timeStamp / (1000 * 3600 * 24));
      let timeStampHrs = Math.floor(timeStamp / (1000 * 3600));
      let timeStampMins = Math.floor(timeStamp / (1000 * 60));
      let timeStampSec = Math.floor(timeStamp / (1000));
      if (timeStampDays > 1) {
        return timeStampDays + 'd'
      }
      else if (timeStampHrs >= 1) {
        return timeStampHrs + 'h'
      }
      else if (timeStampMins >= 1) {
        return timeStampMins + 'm'
      }
      else if (timeStampSec >= 1) {
        return timeStampSec + 's'
      }
    }
  
    public navigateToNotificationFlow(event, notificationId: number,
      notificationCategory: string, navigationDetails, navigationKey: string): void {
      event.stopPropagation();
      this.notificationService.updateNotificationRedirection(navigationKey, navigationDetails);
      this.notificationService.updateNotificationList(notificationId, notificationCategory)
        .subscribe((response: CommonApiResponse) => {
          if (response.responseCode === '2001') {
            console.log('Updation Success');
            if (navigationKey == "") {
              this.notifications = [];
              this.authService.initiateNotificationInfo();
              this.alertOptions.pageNo = -1;
              this.getNotificationList(this.alertOptions);
            }
          }
          else if (response.responseCode == "4002") {
            this.backEndValidationError = true;
          }
          else if (response.responseCode == "4001") {
            this.applicationError = true;
          }
        });
    }
  
    public onScroll(): void {
      let totalTabCount = 0;
      if (this.alertOptions.notificationType == 'All') {
        totalTabCount = this.notificationCount.total;
      }
      else {
        totalTabCount = this.notificationCount.alerts;
      }
      if (totalTabCount / (this.alertOptions.pageSize * ((this.alertOptions.pageNo == -1 ? 0 : this.alertOptions.pageNo) + 1)) > 1) {
        if (!this.tabClicked) {
          this.alertOptions.pageNo = this.alertOptions.pageNo + 1;
          this.getNotificationList(this.alertOptions);
        }
      }
    }
  }

