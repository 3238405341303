import { Component, EventEmitter, Input, Output,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { AdamConf } from '@app/app.config';
import { MaterialsManagementContainerComponent } from 'src/app/core/dashboard/materials-management/materials-management-container/materials-management-container.component';
import { MagnifiedViewModel } from '../../models/materials-management-dashboard.model';
import { InventoryTileComponent} from 'src/app/core/dashboard/materials-management/inventory-tile/inventory-tile.component'
import {TodayProgressTrackerTileComponent} from 'src/app/core/dashboard/materials-management/today-progress-tracker-tile/today-progress-tracker-tile.component'
import { DispensedRestockedTileComponent} from 'src/app/core/dashboard/materials-management/dispensed-restocked-tile/dispensed-restocked-tile.component'
import {BoxesOrderedReplenishedTileComponent} from 'src/app/core/dashboard/materials-management/boxes-ordered-replenished-tile/boxes-ordered-replenished-tile.component'


@Component({
  selector: 'app-materials-management-tile-magnifier',
  templateUrl: './materials-management-tile-magnifier.component.html',
  styleUrls: ['./materials-management-tile-magnifier.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    DialogModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MaterialsManagementContainerComponent,
    InventoryTileComponent,
    TodayProgressTrackerTileComponent,
    DispensedRestockedTileComponent,
    BoxesOrderedReplenishedTileComponent
],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class MaterialsManagementTileMagnifierComponent {
  @Input() showMagnifiedViewPopupData: MagnifiedViewModel;
  @Output() showMagnifiedTilePopup = new EventEmitter<MagnifiedViewModel>();
  public adamLabels = AdamConf;

  public showMagnifiedPopup(event: any): void {
    this.showMagnifiedViewPopupData = event;
    this.showMagnifiedTilePopup.emit(this.showMagnifiedViewPopupData);
  }
}
