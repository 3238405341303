import { Injectable } from '@angular/core';
import { BaseService } from '../../core/base.service';
import { Router } from '@angular/router';
import { StorageService } from '../storage.service';
import { UtilityService } from 'src/app/shared/utility.service';
import { Observable } from 'rxjs';
import { NotificationApiResponse, CommonApiResponse, NotificationDismissParam, AlertOptionsInfo, NotificationLatestResponse } from '../models/header.model';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  isMobileFlag: any;

  constructor(
    private readonly baseService: BaseService,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly utilityService: UtilityService) {
    this.isMobileFlag = this.utilityService.isMobile();
  }

  getNotificationList(params: AlertOptionsInfo): Observable<NotificationApiResponse> {
    return this.baseService.postViaObjectParam('notification-management/api/v1/notify/alerts/notifications', params)
  }

  updateNotificationList(notificationId: number, notificationCategory: string): Observable<CommonApiResponse> {
    return this.baseService.postViaObjectParam('notification-management/api/v1/notify/updatestatus?notificationId=' + notificationId + '&notificationCategory=' + notificationCategory, '');
  }

  getLatestNotification(): Observable<NotificationLatestResponse> {
    return this.baseService.getViaParam('notification-management/api/v1/notify/alerts/latest', '');
  }

  getSystemAlerts(): Observable<NotificationApiResponse> {
    return this.baseService.getViaParam('notification-management/api/v1/notify/alerts/system', '');
  }

  updateNotificationRedirection(navigationKey: string, navigationDetails: any) {
   
    switch (navigationKey.toLowerCase()) {
      case 'replenish':
        if (!this.isMobileFlag) {
          this.router.navigate(['inventories', 'replenishment', 'select-unit']);
        } else {
          this.router.navigate(['inventories', 'hand-held-scanner', 'select-unit']);
        }
        break;

      case 'unitstatus':
        this.router.navigate(['system-management', 'unit-status']);
        break;

      case 'casestatus':
        this.router.navigate(['pick-request', 'case-status']);
        break;

      case 'recall':
       
        // Using array syntax for navigation
        this.router.navigate(['inventories', 'recall', 'select-unit', navigationDetails.recallId]);
        break;

      case 'expire':
        this.storageService.setSessionItem("expire-product-selected", 
          JSON.stringify(navigationDetails.expiredProductDto));
        if (this.router.routerState.snapshot.url.includes('/inventories/expire/select-unit')) {
          window.location.reload();
        } else {
          this.router.navigate(['inventories', 'expire', 'select-unit']);
        }
        break;

      case 'order':
        this.router.navigate([
          'inventories', 
          'order-management', 
          'order-recommendation', 
          { data: navigationDetails.orderId }
        ]);
        break;

      case 'request_access':
        this.router.navigate(
          ['user-management', 'staff-permission', 'approve-access'], 
          { state: { data: 'approveAccess' } }
        );
        break;

      case 'productissues':
        this.router.navigate([
          'system-management', 
          'product-issues', 
          navigationDetails.productIssueId
        ]);
        break;

      case 'orderhistory':
        this.router.navigate([
          'inventories', 
          'order-management', 
          'history', 
          navigationDetails.orderId
        ]);
        break;

      default:
    }
  }

  dismissTrayGroupNotification(params: NotificationDismissParam): Observable<CommonApiResponse> {
    return this.baseService.postViaObjectParam('notification-management/api/v1/notify/dismiss', params)
  }

  loadNotificationCategoryJSONData() {
    return this.baseService.getViaParam('notification-management/api/v1/notify/notification/notification-category-data','')
  }

}
