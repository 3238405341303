<div class="selectUnitContainer staff-permission p-tab-custom user-setup">
  <div class="row navigation">
    <div
      class="offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-9 col-xl-8 col-md-7 ps-0 pr-0 col-sm-7 col-xs-6 mt-8 grid-space"
      [ngClass]="{'marginleft_setupstyle': currentPage === 'changePassword', 'marginleft_style': currentPage !== 'changePassword'}">
      @if (currentPage === 'topic') {
        <h1 class="header1 black f-60 t-space">
        {{adam_labels.acount_settings_module.topicTitle}}</h1>
      }
      @if (currentPage === 'notification') {
        <h1 class="header1 black f-60 t-space-notif">
        {{adam_labels.acount_settings_module.alertsTitle}}</h1>
      }
      @if (currentPage === 'changePassword') {
        <app-reset-password
        (isUserSetupNext)="isUserSetupContinue($event)"></app-reset-password>
      }
      <!--<app-topics  *ngIf="currentPage === 'topic'" (isUserSetupNext)="isUserSetupContinue($event)"></app-topics>-->
      <!-- <app-notification *ngIf="currentPage === 'notification'"
    (isUserSetupNext)="isUserSetupContinue($event)"></app-notification> -->
    @if (currentPage === 'login') {
      <app-login (isUserSetupNext)="isUserSetupContinue('login')"></app-login>
    }
  </div>
</div>
</div>