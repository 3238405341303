<div class="dashboard p-tab-custom">
    @if (showAlert) {
    <div class="notification-div">
        <div class="notification-left" pTooltip="{{notificationBarContent.body.content.msg}}"
            tooltipStyleClass="notification-tooltip" tooltipPosition="bottom">
            <em class="warning-icon"></em>
            {{notificationBarContent.body.content.msg}}
        </div>
        <div class="notification-right" pTooltip="{{this.notificationBarContent.body.action.text}}"
            tooltipStyleClass="notification-tooltip" tooltipPosition="bottom">
            <a (click)="redirectNotification()" class="links pr-2">{{this.notificationBarContent.body.action.text}}</a>
            <em *ngIf="notificationBarContent.body.action.navigationKey != ''" class="arrow-right-icon"></em>
        </div>
    </div>
    }
    <p-tabView [(activeIndex)]="activeTab" (onChange)="dashboardTabChange($event)">
        @for (menuItems of tabPanel; track $index) {
        <p-tabPanel header="{{menuItems.subMenuName}}" [cache]="false">
            <ng-template pTemplate="content">
                <ng-container *ngIf="currentRoute">
                    <router-outlet *ngIf="currentRoute === routerLink"></router-outlet>
                </ng-container>
            </ng-template>
        </p-tabPanel>
        }
    </p-tabView>
</div>