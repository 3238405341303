<div class="notification-list">
  <div class="row content">
      <div class="offset-xl-2 offset-lg-0 offset-md-0 offset-sm-0 col-xl-8 col-lg-10 col-md-9 col-sm-8">
          <div class="row">
              <h1 class="header1 f-60 t-space">{{adam_labels.notificationPage_module.title}}</h1>
          </div>
          <div class="row alertFilter">
              <div class="f-14 text-center" [ngClass]="{'active':alertFilter.all}" (click)="alertFilterClick('all')">{{adam_labels.notificationPage_module.all}} ({{notificationCount.total}})</div>
              <div class="f-14 text-center" [ngClass]="{'active':alertFilter.alert}" (click)="alertFilterClick('alert')">{{adam_labels.notificationPage_module.alerts}} ({{notificationCount.alerts}})</div>
          </div>
          <div class="row dateFilter">
              <div class="">
                  <div class="row itemCon black f-12">
                      <div [ngClass]="{'active':dateFilter.week}"  (click)="dateFilterClick('week')">{{adam_labels.notificationPage_module.oneWeek}}</div>
                      <div [ngClass]="{'active':dateFilter.days}" (click)="dateFilterClick('days')">{{adam_labels.notificationPage_module.days}}</div>
                      <div [ngClass]="{'active':dateFilter.all}" (click)="dateFilterClick('all')">{{adam_labels.notificationPage_module.all}}</div>
                  </div>

              </div>
          </div>
          <div class="row listCon">
              <app-infinite-scroll (scrolled)="onScroll()" class="app-infinite-scroll">
              <div class="row listItem" 
              [ngClass]="{ 'done': notItem.status.toLowerCase() == 'completed' || notItem.status.toLowerCase() == 'notified' }" 
              *ngFor="let notItem of notifications">
                  <div class="notImg text-center" >
                      <em *ngIf="notItem.isAlert" class="material-icons-round notification">warning</em>
                  </div>
                  <div class="notContent">
                      <div class="content f-18 text-left">{{notItem.body.content.msg}}</div>
                      <div class="regular timestamp f-12 lighten" *ngIf = "!(notItem.notificationDate | customDate : 'isToday')">{{notItem.notificationDate  | customDate : 'date'}}</div>
                      <div class="regular timestamp f-12 lighten" *ngIf = "(notItem.notificationDate | customDate : 'isToday')">{{notItem.timeStamp}} ago</div>
                  </div>
                  <div class="notAction text-left semibold f-16" *ngIf="notItem.status.toLowerCase() != 'completed'">
                      <a (click) = "navigateToNotificationFlow($event,notItem.notificationId, notItem.category, notItem.body.action.params,notItem.body.action.navigationKey)" [ngClass]="{ 'red': notItem.isAlert }">
                        <span>{{notItem.body.action.text}}</span><em class="material-icons f-13"  *ngIf = "notItem.body.action.navigationKey.length != 0">arrow_forward</em>
                      </a>
                  </div>
                  <div class="notAction text-left regular f-16" *ngIf="notItem.status.toLowerCase() == 'completed'">
                        <span >{{notItem.body.action.text}}</span>
                  </div>
              </div>
              </app-infinite-scroll>
              <div *ngIf="noResults" class="no-records">
                      {{adam_labels.notificationPage_module.noNotification}}
              </div>
          </div>
      </div>
  </div>
</div>