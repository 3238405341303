<div class="row">
    <div class="materials-management-kpi-tile mb-4 me-4">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card mb-4">
                    <div class="card-body pt-0 materials-management-picking-summary">
                        <app-core-dashboard-materials-management-picking-summary-tile></app-core-dashboard-materials-management-picking-summary-tile>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card mb-4">
                    <div class="card-body pt-0 summary-tile">
                        <app-core-dashboard-materials-management-summary-tile [displayTile]="displayRestocking"></app-core-dashboard-materials-management-summary-tile>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card mb-4">
                    <div class="card-body pt-0 summary-tile">
                        <app-core-dashboard-materials-management-summary-tile [displayTile]="displayReplenishment"></app-core-dashboard-materials-management-summary-tile>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card mb-4">
                    <div class="card-body pt-0 summary-tile">
                        <app-core-dashboard-materials-management-summary-tile [displayTile]="displayOrdering"></app-core-dashboard-materials-management-summary-tile>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card mb-4">
                    <div class="card-body pt-0 summary-tile">
                        <app-core-dashboard-materials-management-summary-tile [displayTile]="displayTransfers"></app-core-dashboard-materials-management-summary-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="materials-management-graph-tile">
        <div class="row">
            <div class="col-xl-6 col-lg-5 col-md-9">
                <div class="card mb-4">
                    <div class="card-body pt-0">
                        <app-core-dashboard-materials-management-today-progress-tracker-tile (showMagnifiedPopup)="showMagnifiedPopup($event)" [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"></app-core-dashboard-materials-management-today-progress-tracker-tile>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-9">
                <div class="card mb-4">
                    <div class="card-body pt-0">
                        <app-core-dashboard-materials-management-dispensed-restocked-tile (showMagnifiedPopup)="showMagnifiedPopup($event)" [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"></app-core-dashboard-materials-management-dispensed-restocked-tile>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-5 col-md-9">
                <div class="card mb-4">
                    <div class="card-body pt-0 custom-height-inv">
                        <app-core-dashboard-materials-management-inventory-tile (showMagnifiedPopup)="showMagnifiedPopup($event)" [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"></app-core-dashboard-materials-management-inventory-tile>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-9">
                <div class="card mb-4">
                    <div class="card-body pt-0 custom-height-inv">
                        <app-core-dashboard-materials-management-boxes-ordered-replenished-tile (showMagnifiedPopup)="showMagnifiedPopup($event)" [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"></app-core-dashboard-materials-management-boxes-ordered-replenished-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
@if (showMagnifiedViewPopupData.tile !== '') {
 <app-materials-management-tile-magnifier [showMagnifiedViewPopupData]="showMagnifiedViewPopupData" (showMagnifiedTilePopup)="showMagnifiedPopup($event)" ></app-materials-management-tile-magnifier> 
}
@if (isDisplayFilter) {
<app-materials-management-tile-filter [filterConfig]="filterConfig" (closeFilter)="isDisplayFilter = false" (appliedFilter)="applyFilter($event)" ></app-materials-management-tile-filter>
}
