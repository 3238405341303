import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdamConf } from '../app.config';

@Injectable()
export class SpinnerHandlerInterceptor implements HttpInterceptor {
  loaderExclusions = AdamConf.LOADER_EXCLUSIONS;
  private pendingRequests = new Set<string>();

  constructor(private spinner: NgxSpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldExclude = this.loaderExclusions.some(path => req.url.includes(path));

    if (!shouldExclude) {
      this.addRequest(req.url);
    }

    return next.handle(req).pipe(
      tap({
        next: event => {
          if (event instanceof HttpResponse) {
            if (!shouldExclude) {
              this.removeRequest(req.url);
            }
          }
        },
        error: error => {
          if (error instanceof HttpErrorResponse) {
            if (!shouldExclude) {
              this.removeRequest(req.url);
            }
          }
        }
      }),
      finalize(() => {
        if (!shouldExclude) {
          this.removeRequest(req.url);
        }
      })
    );
  }

  private addRequest(url: string): void {
    this.pendingRequests.add(url);
    this.spinner.show();
  }
  
  private removeRequest(url: string): void {
    this.pendingRequests.delete(url);
    if (this.pendingRequests.size === 0) {
      this.spinner.hide();
    }
  }
}