<div class="financial-overview-card">
  <div class="header pt-2 ">
    <span class="card-heading">Financial Overview</span>
    <button class="btn btn-close-white button-info" [hidden]=true>
      <em class="material-icons">info_outline</em>
    </button>
    <div class="filter-expand">
      <div class="filter-container" (click)="displayFilter()">
        <p class="filter-text">Filters <em class="material-icons filter-icon">filter_list</em></p>
      </div>
      @if (showMagnifiedViewPopupData.isMagnifiedView===false) {
        <div class="display" (click)="showMagnifiedView()">
          <img class="expand-img" src="./assets/img/maximize_icon.png" alt="Expand image" />
        </div>
      }
      @if (showMagnifiedViewPopupData.isMagnifiedView===true) {
        <div class="display" (click)="showMagnifiedView()">
          <img class="expand-img" src="./assets/img/minimize_icon.png" alt="Expand image" />
        </div>
      }
    </div>
  </div>

  <div class="row tile-body">
    <div class="col-xl-2 col-lg-2 col-md-3 mb-4 column">

      <div class="financial-left-tile">
        <div class="financial-tile mt-5">
          <div class="tile-heading">Cumulative YTD</div>
          <div class="row">
            <span class="col-xl-5 p-0 content-header">
              {{adamLabels.financialOverview_module.budgetLabel}}
            </span>
            <span class="col-xl-1 p-0"></span>
            <span class="col-xl-6 p-0 budget-dollars-value">
              {{cumulativeYTDBudget}}
            </span>
          </div>
          <div class="row">
            <span class="col-xl-5 p-0 content-header">
              {{adamLabels.financialOverview_module.actualLabel}}
            </span>
            <span class="col-xl-1 p-0"></span>
            <span class="col-xl-6 p-0 actual-percent-value">
              {{cumulativeYTDActual}}
            </span>
          </div>
        </div>
        <div class="financial-tile">
          <div class="tile-heading">Delta YTD</div>
          <div class="row">
            <span class="col-xl-4 p-0 content-header ms-2">
              {{adamLabels.financialOverview_module.dollarsLabel}}
            </span>
            <span class="col-xl-1 p-0">
              @if (deltaYTDIndicator===adamLabels.financialOverview_module.greenIndicator && deltaYTDDirection===adamLabels.financialOverview_module.downDirection) {
                <em class="material-icons green-indicator margin-negative">
                  arrow_drop_down
                </em>
              }
              @if (deltaYTDIndicator===adamLabels.financialOverview_module.redIndicator && deltaYTDDirection===adamLabels.financialOverview_module.upDirection) {
                <em class="material-icons red-indicator">
                  arrow_drop_up
                </em>
              }
            </span>
            <span class="col-xl-6 p-0 budget-dollars-value margin-l">
              {{deltaYTDDollars}}
            </span>
          </div>
          <div class="row">
            <span class="col-xl-4 p-0 content-header ms-2">
              {{adamLabels.financialOverview_module.percentLabel}}
            </span>
            <span class="col-xl-1 p-0">
              @if (deltaYTDIndicator===adamLabels.financialOverview_module.greenIndicator && deltaYTDDirection===adamLabels.financialOverview_module.downDirection) {
                <em class="material-icons green-indicator margin-negative">
                  arrow_drop_down
                </em>
              }
              @if (deltaYTDIndicator ===adamLabels.financialOverview_module.redIndicator && deltaYTDDirection===adamLabels.financialOverview_module.upDirection) {
                <em class="material-icons red-indicator">
                  arrow_drop_up
                </em>
              }
            </span>
            <span class="col-xl-6 p-0 actual-percent-value margin-l">
              {{deltaYTDPercent}}
            </span>
          </div>
        </div>
        <div class="financial-tile">
          <div class="tile-heading">Year-End Forecast</div>
          <div class="row">
            <span class="col-xl-5 p-0 content-header">
              {{adamLabels.financialOverview_module.budgetLabel}}
            </span>
            <span class="col-xl-1 p-0"></span>
            <span class="col-xl-6 p-0 budget-dollars-value">
              {{yearEndForecastBudget}}
            </span>
          </div>
          <div class="row">
            <span class="col-xl-5 p-0 content-header">
              {{adamLabels.financialOverview_module.actualLabel}}
            </span>
            <span class="col-xl-1 p-0"></span>
            <span class="col-xl-6 p-0 actual-percent-value">
              {{yearEndForecastActual}}
            </span>
          </div>
        </div>
        <div class="financial-tile">
          <div class="tile-heading">Forecasted Year-End Delta</div>
          <div class="row">
            <span class="col-xl-4 p-0  content-header ms-2">
              {{adamLabels.financialOverview_module.dollarsLabel}}
            </span>
            <span class="col-xl-1 p-0">
              @if (forecastedYearEndDeltaIndicator===adamLabels.financialOverview_module.greenIndicator  && forecastedYearEndDeltaDirection===adamLabels.financialOverview_module.downDirection) {
                <em
                  class="material-icons green-indicator margin-negative">
                  arrow_drop_down
                </em>
              }
              @if (forecastedYearEndDeltaIndicator===adamLabels.financialOverview_module.redIndicator && forecastedYearEndDeltaDirection===adamLabels.financialOverview_module.upDirection) {
                <em class="material-icons red-indicator">
                  arrow_drop_up
                </em>
              }
            </span>
            <span class="col-xl-6 p-0 budget-dollars-value margin-l">
              {{forecastedYearEndDeltaDollars}}
            </span>
          </div>
          <div class="row">
            <span class="col-xl-4 p-0 content-header ms-2">
              {{adamLabels.financialOverview_module.percentLabel}}
            </span>
            <span class="col-xl-1 p-0">
              @if (forecastedYearEndDeltaIndicator===adamLabels.financialOverview_module.greenIndicator && forecastedYearEndDeltaDirection===adamLabels.financialOverview_module.downDirection) {
                <em
                  class="material-icons green-indicator margin-negative">
                  arrow_drop_down
                </em>
              }
              @if (forecastedYearEndDeltaIndicator===adamLabels.financialOverview_module.redIndicator && forecastedYearEndDeltaDirection===adamLabels.financialOverview_module.upDirection) {
                <em class="material-icons red-indicator">
                  arrow_drop_up
                </em>
              }
            </span>
            <span class="col-xl-6 p-0 actual-percent-value margin-l">
              {{forecastedYearEndDeltaPercent}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-10 col-lg-8 col-md-6 column financial-graph-data">
      <div class="legend pt-2">
        <span class="budget-dollars-value">
          <img class="icon-style" src="./assets/img/Budget.svg" alt="Budget image" />
          Budget
        </span>
        <span class="budget-dollars-value ms-3">
          <em class="material-icons legend-icon blue-icon">
            commit
          </em>
          Actual
        </span>
        <span class="budget-dollars-value ms-3">
          <img class="icon-style pe-1" src="./assets/img/forecast.svg" alt="Forecast image" />
          Forecast
        </span>
      </div>
      <app-line-chart-clinical [lineChartData]="lineChartData" [chartMetaData]="chartMetaData">
      </app-line-chart-clinical>
    </div>
  </div>
</div>