import { Component, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import * as d3 from 'd3';
import { MousePosition } from 'src/app/core/dashboard/models/clinical-dashboard-model.model';


@Component({
  selector: 'app-line-chart-clinical',
  templateUrl: './line-chart-clinical.component.html',
  styleUrls: ['./line-chart-clinical.component.scss'],
  standalone: true
})
export class LineChartClinicalComponent implements OnChanges {

    @Input() lineChartData: any;
    @Input() chartMetaData: any;
    @ViewChild('lineChartContainer', { static: false })
    private lineChartContainer: ElementRef;
  
    constructor() {
      // constructor
    }
  
    public ngOnChanges(): void {
      if (this.lineChartData && this.lineChartData.categories) {
        this.lineChartData.categories.push(null);
        this.initChart();
      }
    }
  
    private initChart(): void {
      d3.select(this.lineChartContainer.nativeElement).selectAll('svg').remove();
      const width = this.lineChartContainer.nativeElement.offsetWidth - this.chartMetaData.margin.left - this.chartMetaData.margin.right;
      const height = this.lineChartContainer.nativeElement.offsetHeight - this.chartMetaData.margin.top - this.chartMetaData.margin.bottom;
    
      const x = d3.scalePoint()
        .domain(this.lineChartData.categories)
        .range([0, width]);
    
      const y = d3.scaleLinear()
        .range([height, 0]);
    
      const xAxis = d3.axisBottom(x)
      .tickSize(0);
    
      const yAxis = d3.axisLeft(y)
      .tickSize(0)
        .tickFormat((d) => {
          if (this.chartMetaData.yAxis === 'percentage') {
            return d3.format('.0%')(Number(d)/ 100); // Format as percentage
          } else if (this.chartMetaData.yAxis === 'currency') {
            return '$' + d3.format('~s')(Number(d)); // Format as currency
          } else {
            return d3.format(',.2f')(d); // Default format
          }
        });
    
      const line = d3.line()
        .defined((d: any) => d.count !== null)
        .curve(d3.curveMonotoneX)
        .x((d: any) => x(d.month))
        .y((d: any) => y(d.count));
    
      const svg = d3.select(this.lineChartContainer.nativeElement)
        .append('svg')
        .attr('width', width + this.chartMetaData.margin.left + this.chartMetaData.margin.right)
        .attr('height', height + this.chartMetaData.margin.top + this.chartMetaData.margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + this.chartMetaData.margin.left + ',' + this.chartMetaData.margin.top + ')');
    
      // Format data
      const graphLines = this.lineChartData.dataSeriesCollection.map((d) => {
        const category = d.legend;
        let i = 0;
        const dataPoints = d.dataPoints.map((dataPiont) => {
          return {
            month: this.lineChartData.categories[i++],
            count: dataPiont.value,
            tooltipData: dataPiont.toolTipInfo,
            legend: category
          };
        });
        return {
          name: category,
          values: dataPoints
        };
      });
    
      x.domain(this.lineChartData.categories);
    
      if (this.chartMetaData.yAxis === 'percentage') {
        const endLimit = 100;
        y.domain([0, endLimit]);
      } else {
        const yDomain: [number, number] = [
          d3.min(graphLines, (c: { values: { count: number }[] }) => {
            return d3.min(c.values, (v: { count: number }) => v.count);
          }) as number,
          d3.max(graphLines, (c: { values: { count: number }[] }) => {
            return d3.max(c.values, (v: { count: number }) => v.count);
          }) as number
        ];
        y.domain(yDomain);
      }
    
      // Render line chart
      // Draw X axis
      svg.append('g')
        .attr('class', 'x axis xAxis')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis)
        .select('path')
        .style('stroke', '#d0cfcd')
        .style('fill', 'none')
        .style('stroke-width', 1)
        .style('shape-rendering', 'crispEdges');
    
      // Draw Y axis
      svg.append('g')
        .attr('class', 'y axis yAxis')
        .call(yAxis)
        .select('path')
        .style('stroke', '#d0cfcd')
        .style('fill', 'none')
        .style('stroke-width', 1)
        .style('shape-rendering', 'crispEdges')
        .style('stroke-dasharray', '2');
    
      // Draw Grid lines - X axis
      svg.selectAll('g.xAxis g.tick')
        .append('line')
        .attr('class', 'gridline x')
        .attr('x1', 0)
        .attr('y1', -height)
        .attr('x2', 0)
        .attr('y2', 0)
        .attr('stroke-width', 1)
        .style('opacity', '1')
        .style('stroke', '#d0cfcd')
        .style('stroke-dasharray', '2');
    
      // Draw Grid lines - Y axis
      svg.selectAll('g.yAxis g.tick')
        .append('line')
        .attr('class', 'gridline y')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', width)
        .attr('y2', 0)
        .attr('stroke-width', 1)
        .style('opacity', '1')
        .style('stroke', '#d0cfcd')
        .style('stroke-dasharray', '2');
    
      // Add styles to X axis ticks
      const xAxisTickAdj = 2;
      svg.selectAll('g.xAxis g.tick text')
        .style('text-anchor', 'end')
        .style('font-size', '10px')
        .style('font-family', 'Noto Sans Regular')
        .attr("transform", "translate(" + (width / this.lineChartData.categories.length) / xAxisTickAdj + ", 0),rotate(-45)");
    
      // Add styles to Y axis ticks
      svg.selectAll('g.yAxis g.tick text')
        .style('font-size', '8px')
        .style('font-family', 'Noto Sans Regular')
        .style('text-transform', 'uppercase');
    
      // Draw Plot Lines
      const graphLine = svg.selectAll('.chart-line')
        .data(graphLines)
        .enter()
        .append('g')
        .attr('class', 'chart-line');
    
      const plotLineAdj = 2;
      graphLine.append('path')
        .attr('class', (d: { name: string; values: any[] }) => d.name === 'Forecast' ? 'dashed-line' : 'line')
        .attr('d', (d: { name: string; values: any[] }) => line(d.values))
        .attr("transform", "translate(" + (width / this.lineChartData.categories.length) / plotLineAdj + ", 0)")
        .style('stroke', (d: { name: string; values: any[] }) => this.resolveLineColor(d.name))
        .style('stroke-width', '3px')
        .style('stroke-dasharray', (d: { name: string; values: any[] }) => {
          const forcatVal = 5;
          const defaultVal = 0;
          return d.name === 'Forecast' ? forcatVal : defaultVal;
        })
        .style('fill', 'none');
    
      // Add circles for each plot    
      this.appendCircle(graphLine, x, y, width);
    }
    
  
    private appendCircle(graphLine: any, x: any, y: any, width: any): void {
      const tranformAdjVal = 2;
      graphLine.append('g').selectAll('circle')
        .data((d) => d.values)
        .enter()
        .append('circle')
        .attr('r', (d) => {
          const countVal = 4;
          return d.count === null ? 0 : countVal})
        .attr('cx', (d) => x(d.month))
        .attr('cy', (d) => y(d.count))
        .attr('fill', (d) => d.legend === 'Budget' ? this.resolveLineColor(d.legend) : '#FFF')
        .attr('stroke', (d) => d.legend === 'Budget' ? this.resolveLineColor(d.legend) : 'black')
        .attr("transform", "translate(" + (width / this.lineChartData.categories.length) / tranformAdjVal + ", 0)")
        .on('mouseover', (event, d) => {
            const duration = 200;
            const opacity = 0.9;
            const topAdj = 28;
            const div = d3.select('body').append('div')
              .attr('class', 'tooltip-chart-line')
              .style('opacity', 0);
            div.transition()
              .duration(duration)
              .style('opacity', opacity);
            div.html(this.getTooltipContent(d))
              .style('left', () => {
                const leftAdj = 10;
                var pos = this.positionTooltip(
                  {
                    x: event.pageX,
                    y: event.pageY
                  });
                return (pos.left + leftAdj) + 'px';
              })
              .style('top', (event.pageY - topAdj) + 'px')
              .style('z-index', '10001');
          })
          
        .on('mouseout', () => {
          let tooltip = document.getElementsByClassName('tooltip-chart-line');
          while (tooltip[0]) {
            tooltip[0].parentNode.removeChild(tooltip[0]);
          }
          const duration = 500;
          let div = d3.select('tooltip-chart-line')
          div.transition()
            .duration(duration)
            .style('opacity', 0);
        });
    }
  
    private positionTooltip(mouse): MousePosition {
      let box = document.querySelector('.graph-table');
      let style = getComputedStyle(box);
      let toolwidth = parseInt(style.width);
      const toolMaxWidth = 100;
      //Distance of element from the right edge of viewport
      if (window.innerWidth - (mouse.x + toolwidth) < toolMaxWidth) { //If tooltip exceeds the X coordinate of viewport
        const mouseAdj = 30;
        mouse.x = mouse.x - toolwidth - mouseAdj;
      }
      return {
        top: mouse.y,
        left: mouse.x
      };
    }
  
    private getTooltipContent(d): string {
      let tooltipContent = '';
      tooltipContent = tooltipContent + '<table class="graph-table" style="width: 150px; ">';
      if (d.tooltipData) {
          d.tooltipData.forEach(element => {
              tooltipContent += `<tr><td class="title" style="font-size: 8px;">${element.label}:</td><td class="data-value" style="font-size: 8px;"> ${element.value} </td></tr>`;
          });
      }
      tooltipContent = tooltipContent + '</table>';
      return tooltipContent;
  }
  
  
    private resolveLineColor(lineName: string): string {
      let colorHexValue = '';
      switch (lineName) {
        case 'Preference Card Accuracy':
          colorHexValue = '#12224D';
          break;
        case 'Forecast':
          colorHexValue = '#12224D';
          break;
        case 'Actual':
          colorHexValue = '#12224D';
          break;
        case 'Budget':
          colorHexValue = '#888B8D';
          break;
        default:
          colorHexValue = '#12224D';
          break;
      }
      return colorHexValue;
    }
  
  }
