<div class="reset-pwd">
    <div class="row">
        <div
            [ngClass]="!isUserSetup ? 'offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-6 col-xl-4 col-md-10 ps-0 pe-0 col-sm-12 col-xs-10 mt-8 forgot_pwd_padding' :'col-lg-6 col-xl-4 col-md-10 ps-0 pe-0 col-sm-12 col-xs-6  mt-8 mt-user-setup-8'">

            <div>
                <div class="pb-2">
                    @if (!routeToForgotPwd && !routeToPwdExpired) {
                    <h1 class="header1 f-60 padding-style">
                        {{adam_labels.user_management_module.setUpPasswordHeader}} {{fname}} {{lname}}.</h1>
                    }
                    @if (!routeToForgotPwd && !routeToPwdExpired) {
                    <label class="sub-title sub-title1">{{adam_labels.user_management_module.setUpPasswordSubHeader1}}
                        {{adam_labels.user_management_module.setUpPasswordSubHeader2}}</label>
                    }
                    @if (routeToForgotPwd && !resetSuccess && !routeToPwdExpired) {
                    <h1 class="header1 f-60 resetHeader">
                        {{adam_labels.user_management_module.resetPasswordHeader}}</h1>
                    }
                    @if (routeToPwdExpired && !resetSuccess ) {
                    <h1 class="header1 f-60 padding-style pwd_expiry_header">Password expired</h1>
                    }
                    @if (routeToPwdExpired && !resetSuccess ) {
                    <label class="sub-title sub-title1 pwd_expiry_header">Your password has expired. Please reset
                        your password.</label>
                    }
                </div>
                @if (!resetSuccess) {
                <div class="formContainer " [ngClass]="routeToForgotPwd ? 'form-style':'usersetup-padd'">
                    <form [formGroup]="resetPwdForm" (ngSubmit)="onsetPwdSubmit()"
                        [ngClass]="routeToPwdExpired?'pwd_expiry_form':''">
                        @if (resetErrorMsg!='') {
                        <div class="row form-row info-text text-danger">
                            {{resetErrorMsg}}
                        </div>
                        }
                        <div class="row form-row form-input-height mt-4 field_width"
                            [ngClass]="routeToForgotPwd ? 'email_padding':'email_padding_setup'">
                            <mat-form-field>
                                <mat-label>Email</mat-label>
                                <input matInput [readonly]="true" (keyup)="confirmPwdChk()" formControlName="email"
                                    type="text" id="email" class="fadeIn second"
                                    placeholder={{adam_labels.common.emailLabel}} autocomplete="off">
                            </mat-form-field>
                            <!-- @if (f.email.errors) {
                            <div class="form-errors">
                                @if (resetPwdForm.controls.email.dirty && f.email.errors.required) {
                                <div></div>
                                }
                                @if (resetPwdForm.controls.email.dirty && f.email.errors.pattern) {
                                <div></div>
                                }
                            </div>
                            } -->
                        </div>
                        @if (routeToPwdExpired) {
                        <div class="row form-row form-input-height mt-4 field_width">
                            <mat-form-field>
                                <label for="currentPassword"></label>
                                <input matInput formControlName="currentPassword" type="password" id="currentPassword"
                                    onPaste="return false" placeholder="Current password" class="fadeIn second"
                                    maxlength="30" autocomplete="off">
                            </mat-form-field>
                            @if (f.currentPassword.errors) {
                            <div class="form-errors">
                                @if (f.currentPassword.errors.required) {
                                <div>
                                    Current password is required.</div>
                                }
                                @if (currentPwdError!='') {
                                <div>
                                    {{currentPwdError}}</div>
                                }
                            </div>
                            }
                        </div>
                        }
                        <div class="row form-row form-input-height mt-2 tick field_width"
                            [ngClass]="!showPwdPolicy && f.createPassword.value !== '' && !f.createPassword.errors ? 'tick_padding' :''">
                            <mat-form-field>
                                <div class="check-icon-div">
                                    <label for="createPassword"></label>
                                    <input matInput formControlName="createPassword" (keyup)="confirmPwdChk()"
                                        width="100%" type="password" id="createPassword" class="fadeIn second"
                                        onPaste="return false" placeholder={{placeholderValue}} autocomplete="off">
                                    @if (!showPwdPolicy && f.createPassword.value !== '' && !f.createPassword.errors) {
                                    <em class="material-icons check-icon">
                                        check_circle
                                    </em>
                                    }
                                </div>
                            </mat-form-field>
                            @if (f.createPassword.errors) {
                            <div class="form-errors">
                                @if (f.createPassword.errors.required) {
                                <div>
                                    Password is required.</div>
                                }
                            </div>
                            }
                            @if (showPwdPolicy) {
                            <div class="regular f-12 font-weight-style">
                                Passwords should be 8-30 characters and a combination of letters, numbers, and special
                                characters.</div>
                            }
                        </div>
                        <div class="row form-row form-input-height field_width"
                            [ngClass]="!f.createPassword.errors && !f.confirmPassword.errors && f.confirmPassword.value==f.createPassword.value && showCheck && f.confirmPassword.value!='' ? 'tick_padding' :''">
                            <mat-form-field [ngClass]="routeToForgotPwd ? 'confirm_pwd_pad':'mt-2'">
                                <div class="check-icon-div">
                                    <label for="confirmPassword"></label>
                                    <input matInput formControlName="confirmPassword" (keyup)="confirmPwdChk()"
                                        onPaste="return false" type="password" id="confirmPassword"
                                        placeholder={{placeholderValueConfirm}} class="fadeIn second"
                                        autocomplete="off">
                                    @if (!f.createPassword.errors && !f.confirmPassword.errors &&
                                    f.confirmPassword.value==f.createPassword.value && showCheck &&
                                    f.confirmPassword.value!='') {
                                    <em class="material-icons check-icon">
                                        check_circle
                                    </em>
                                    }
                                </div>
                            </mat-form-field>
                            @if (f.confirmPassword.errors) {
                            <div class="form-errors">
                                @if (f.confirmPassword.errors.required) {
                                <div>
                                    Confirm password is required.</div>
                                }
                            </div>
                            }
                            @if (pswdNoMatchEr) {
                            <div class="error-style">Passwords do not match.</div>
                            }
                        </div>
                        <div class="button-div text-right mt-x" [ngClass]="isUserSetup ?'button_setup':'button_reset'">
                            <a (click)="resetForm()" class="semibold pe-5 f-14">{{adam_labels.common.cancel}}</a>
                            @if (routeToForgotPwd || routeToPwdExpired) {
                            <input type="submit" class="btn button-primary-large" value={{adam_labels.common.submit}}>
                            }
                            @if (!routeToForgotPwd && !routeToPwdExpired) {
                            <input type="submit" class="btn button-primary-large" value={{adam_labels.common.next}}>
                            }
                        </div>
                    </form>
                </div>
                }

                @if (resetSuccess) {
                <div>
                    <div class="success-form">
                        <div class="heading-div">
                            <h1 class="header1 f-60 succes_pad">
                                {{adam_labels.user_management_module.resetPwdSuccessHeader}}</h1>
                            <label
                                class="sub-title ms-1 succes_pad1">{{adam_labels.user_management_module.resetPwdSuccessSubHeader}}</label>
                        </div>
                        <div class="button-div1">
                            <button type="button" (click)="cancelRequest()"
                                class="btn button-primary-large">{{adam_labels.user_management_module.setUpPasswordSuccessButton}}</button>
                        </div>
                    </div>
                </div>
                }

            </div>
        </div>
    </div>
</div>