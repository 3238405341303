import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-success',
  standalone: true,
  imports: [],
  templateUrl: './reset-success.component.html',
  styleUrl: './reset-success.component.scss'
})
export class ResetSuccessComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
    // onInit
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

}

