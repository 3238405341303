import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { NotificationService } from '../notification/notification.service';
import { AuthService } from '../../auth/auth.service';
import { NotificationLatestResponse } from '../models/header.model';
import { AdamConf } from '@app/app.config';
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { ChangeDetectorRef } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterModule, CommonModule, TabViewModule, TooltipModule, RouterOutlet ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {
  notificationBarContent: any = {};
  showAlert = false;
  adam_labels: any;
  routerLink: string;
  activeTab: number;
  backEndValidationError: boolean;
  applicationError: boolean;
  tabPanel: any;
  userHomePage: string;
  routerSubscription$: any;
  currentRoute: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef) {
    this.routerLink = this.router.routerState.snapshot.url;
    this.currentRoute = this.routerLink;
  }

  ngOnInit() {
    this.getNotificationBarContent();
    this.adam_labels = AdamConf;
    this.loadBasePage();
    this.subscribeToRouteChanges();
  }

  redirectNotification() {
    this.notificationService.updateNotificationList(this.notificationBarContent.notificationId, this.notificationBarContent.category)
      .subscribe((response) => {
        if (response.responseCode === '2001') {
        } else if (response.responseCode === '4002') {
          this.backEndValidationError = true;
        } else if (response.responseCode === '4001') {
          this.applicationError = true;
        }
      });
    this.notificationService
      .updateNotificationRedirection(this.notificationBarContent.body.action.navigationKey, this.notificationBarContent.body.action.params);
  }

  getNotificationBarContent() {
    this.notificationService.getLatestNotification().subscribe((notifications: NotificationLatestResponse) => {
      if (notifications.responseCode === '2001') {
        if (notifications.responseData && notifications.responseData !== null) {
          this.notificationBarContent = notifications.responseData;
          this.showAlert = true;
        } else {
          this.showAlert = false;
        }
      } else if (notifications.responseCode === '4002') {
        this.backEndValidationError = true;
        this.showAlert = false;
      } else if (notifications.responseCode === '4001') {
        this.applicationError = true;
        this.showAlert = false;
      }
    });
  }





  dashboardTabChange(e) {
    if (this.tabPanel[e.index].link) {
      this.routerLink = this.tabPanel[e.index].link;
      this.currentRoute = null;
            setTimeout(() => {
                this.currentRoute = this.routerLink;
                this.router.navigate([this.routerLink]);
            }, 0); 
  }
  }

  setUserHomePage() {
    if (this.tabPanel) {
      this.tabPanel.forEach((panel, index) => {
        if (panel.subMenuName.toUpperCase() === this.userHomePage) {
          this.activeTab = index;
          this.router.navigate([panel.link]);
        }
      });
    }
  }
  
  loadBasePage() {
    this.authService.menuItems$.subscribe((response) => {
      if (response.permissionType) {
        this.userHomePage = response.homePage;
        response.features.forEach((resData) => {
          if (resData.menuName === 'tabpanel') {
            this.tabPanel = resData.subMenus[0].subMenuNames;
          }
        });
        this.setUserHomePage();
      }
    });
  }  

  subscribeToRouteChanges() {
    this.routerSubscription$ = this.router.events.subscribe((routeChanges) => {
      if (routeChanges instanceof NavigationEnd && this.tabPanel) {
        this.tabPanel.forEach((panel, index) => {
          if (panel.link === routeChanges.url) {
            this.activeTab = index;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription$) {
      this.routerSubscription$.unsubscribe();
    }
  }

}
