<div class="forgot-pwd">
    <div class="row">
        <div
            class="offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-8 col-xl-4 col-md-5 col-sm-6 pl-0 pe-0 col-sm-6 col-xs-10 mt-8">
            <div *ngIf="!requestSubmitted" class="col-xl-12 col-lg-10 col-md-9 col-sm-8 pl-3">
                <div class="formContainer">
                    <div class="row form-row">
                        <h1 class="header1 f-60">{{adam_labels.user_management_module.forgotPasswordHeader}}</h1>
                    </div>
                    <label
                        class="sub-title sub-title1">{{adam_labels.user_management_module.forgotPasswordSubHeader}}</label>
                    <form [formGroup]="forgotForm" (ngSubmit)="onForPassSubmit()">
                        <ng-container *ngIf="errorMessage!=''">
                            <div class="row form-row info-text text-danger">
                                {{errorMessage}}
                            </div>
                        </ng-container>
                        <div class="row form-row mt-4">
                            <mat-form-field>
                                <label for="emailID"></label>
                                <input matInput formControlName="emailID" type="text" id="emailID" width="100%"
                                    class="fadeIn second" placeholder={{adam_labels.common.emailLabel}}
                                    autocomplete="off" spellcheck="false">
                            </mat-form-field>
                            <div *ngIf="f.emailID.errors" class="form-errors">
                                <div *ngIf="submitted && f.emailID.errors.required">
                                    {{adam_labels.error_messages.emailRequiredError}}</div>
                                <div *ngIf="submitted && forgotForm.controls.emailID.dirty && f.emailID.errors.pattern">
                                    {{adam_labels.error_messages.emailValidationError}}</div>
                            </div>
                        </div>

                        <div class="forPassFooter">
                            <a (click)="resetForm()" class="semibold pe-5 f-14">{{adam_labels.common.cancel}}</a>
                            <input type="submit" class="btn button-primary-large ml-2"
                                value={{adam_labels.common.send}}>
                        </div>
                    </form>

                </div>
            </div>

            <div *ngIf="requestSubmitted">
                <div class="success-form">
                    <div class="heading-div1">
                        <h1 class="header1 f-60">{{adam_labels.user_management_module.forgotPasswordSuccessHeader}}</h1>
                        <label
                            class="sub-title sub-title1">{{adam_labels.user_management_module.forgotPasswordSuccessSubHeader}}</label>
                    </div>

                    <div class="button-div1">

                        <button type="button" (click)="cancelRequest()"
                            class="btn button-primary-large">{{adam_labels.common.okayButton}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>