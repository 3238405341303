<div class="req-access">
    <div class="row">
        <div
            class="offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-6 col-xl-4 col-md-5 ps-0 pe-0 col-sm-6 col-xs-6 mt-8">

            <div class="col-md-9 col-sm-8 col-lg-10 ps-3">


                <div *ngIf="!showSuccessPage">
                    <div class="heading-div">
                        <h1 class="header1 f-60">{{adam_labels.user_management_module.reqAccessHeader}}</h1>
                        <label
                            class="sub-title sub-title1">{{adam_labels.user_management_module.reqAccessSubHeader}}</label>
                    </div>
                    <form [formGroup]="requestAccessForm" (ngSubmit)="requestAccessOnSubmit()">
                        <div class="request-access-form">

                            <div class="mt-style">
                                <mat-form-field>
                                    <mat-label class = "form-font">First Name</mat-label>
                                    <input matInput formControlName="name" type="text" id="name" class="fadeIn second"
                                        maxlength="100" autocomplete="off" spellcheck="false">
                                </mat-form-field>
                                <div *ngIf="f.name.errors" class="form-errors">
                                    <div *ngIf="submitted &&  f.name.errors.required" class="error-div">
                                        {{adam_labels.error_messages.firstNameReq}}</div>
                                    <div *ngIf="submitted && f.name.errors.pattern" class="error-div">
                                        {{adam_labels.error_messages.nameValidationError}}</div>
                                </div>
                            </div>
                            <div class="mt-style2">
                                <mat-form-field>
                                    <mat-label class = "form-font">Last Name</mat-label>
                                    <input matInput formControlName="lastName" type="text" id="lastName"
                                        class="fadeIn second"
                                        maxlength="100" autocomplete="off" spellcheck="false">
                                </mat-form-field>
                                <div *ngIf="f.lastName.errors" class="form-errors">
                                    <div *ngIf="submitted && f.lastName.errors.required" class="error-div">
                                        {{adam_labels.error_messages.lastNameReq}}</div>
                                    <div *ngIf="submitted && f.lastName.errors.pattern" class="error-div">
                                        {{adam_labels.error_messages.lnameValidationError}}</div>
                                </div>
                            </div>
                            <div class="mt-style1 select-form">
                                <label class="showRolePlaceHolder book"
                                    *ngIf="showRolePlaceHolder">{{adam_labels.common.roleLabel}}</label>
                                <p-dropdown formControlName="role" (onChange)="showRolePlaceHolderFn()"
                                    class="dropdown-style custom-p-dropdown" [options]="roleList"
                                    placeholder={{adam_labels.common.roleLabel}} optionLabel="roleDesc"></p-dropdown>
                                <div *ngIf="f.role.errors" class="form-errors pt-3">
                                    <div *ngIf="submitted &&  f.role.errors.required" class="error-div">
                                        {{adam_labels.user_management_module.selectRole}}
                                    </div>
                                </div>
                            </div>

                            <div class="mt-style1">
                                <mat-form-field>
                                    <mat-label class = "form-font">Email</mat-label>
                                    <input matInput formControlName="email" type="text" id="email" width="100%"
                                        class="fadeIn second"
                                        maxlength="100" autocomplete="off" spellcheck="false">
                                </mat-form-field>
                                <div *ngIf="f.email.errors" class="form-errors">
                                    <div *ngIf="submitted && f.email.errors.required" class="error-div">
                                        {{adam_labels.error_messages.emailRequiredError}}</div>
                                    <div *ngIf="submitted && f.email.errors.pattern" class="error-div">
                                        {{adam_labels.error_messages.emailValidationError}}</div>
                                </div>
                                <div *ngIf="emailAlreadyExists" class="form-errors">
                                    <div>{{adam_labels.error_messages.emailAlreadyUsed}}</div>
                                </div>
                            </div>

                            <div class="mt-style1">
                                <app-captcha (captchaResponseHandler)="handleCaptchaReponse($event)"></app-captcha>
                                <div *ngIf="submitted && !isCaptchaValidated" class="form-errors">
                                    <div>{{adam_labels.error_messages.invalidCaptcha}}</div>
                                </div>
                            </div>

                            <div class="button-div">
                                <a (click)="cancelRequest()"
                                    class="semibold pe-5 f-14">{{adam_labels.common.cancel}}</a>
                                <button type="submit"
                                    class="btn button-primary-large ms-2">{{adam_labels.common.submit}}</button>
                            </div>
                        </div>
                    </form>
                </div>


                <div *ngIf="showSuccessPage">
                    <div class="success-form">
                        <div class="heading-div1">
                            <h1 class="header1 f-60">{{adam_labels.user_management_module.reqAccessSuccessHeader}}</h1>
                            <label
                                class="sub-title sub-title1">{{adam_labels.user_management_module.reqAccessSccessSubHeader}}</label>
                        </div>

                        <div class="button-div1">

                            <button type="button" (click)="cancelRequest()"
                                class="btn button-primary-large">{{adam_labels.common.okayButton}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>