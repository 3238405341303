import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SearchBarComponent } from '../search-bar/search-bar.component';



@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule,SearchBarComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

  constructor(private router:Router) { }
 

  ngOnInit() {
    // onInit
  }

}
