export const environment = {
  // QA Environment also called BST1
  production: false,
  baseUrl: 'https://test.aitasolution.com/',

  // SAML
  samlUrl: 'https://fedlogin.jnj.com/as/authorization.oauth2?client_id=IOT-AITA-DEV&response_type=code' +
    '&redirect_uri=https://test.aitasolution.com/aita-user-management/api/v1/ops/authorize' +
    '&scope=address%20email%20openid%20phone%20profile',
  samlLogoutUrl: 'https://fedlogin.jnj.com/idp/startSLO.ping',
  enableLog: false
};
