<div class="search-results">
    <div class="row">
        <div class="offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-7 col-xl-6 col-md-9 col-sm-8 col-xs-10">
            <div *ngIf="showResults;else no_results">
                <div class="heading-div" *ngIf="resultDetails.searchResultCount > 0">
                   <h1 class="header1 f-60">{{adam_labels.search_results_module.header}}</h1>
                   <label class="sub-title">You can find {{searchQueryObj.query}} in the following {{resultDetails.searchResultCount}} location<span *ngIf="resultDetails.searchResultCount > 1">s</span>.</label>
                </div>
                <app-infinite-scroll (scrolled)="onScroll()">
                <ng-container  *ngFor="let currentResult of searchResultList">
                    <p-table #dt [columns]="cols" [value]="currentResult.details">
                    <ng-template pTemplate="caption">
                      <label class="tbl-caption header3"> {{currentResult.productCode}} </label> <br>
                      <label class="tbl-caption header4"> {{currentResult.productDescription}} </label> <br>
                      <label class="sub-title">
                        <span [hidden]="!currentResult.needleName || currentResult.needleName === 'NULL'">Needle Name: {{currentResult.needleName}}<br/></span>
                        <span [hidden]="!currentResult.needlePointType || currentResult.needlePointType === 'NULL'">Needle Point Type: {{currentResult.needlePointType | titlecase}}<br/></span>
                        <span [hidden]="!currentResult.reloadColor || currentResult.reloadColor === 'NULL'">Color: {{currentResult.reloadColor | titlecase}}<br/></span>
                        <span [hidden]="!currentResult.absorbability || currentResult.absorbability === 'NULL'">Absorbable: {{currentResult.absorbability | titlecase}}<br/></span>
                        <span [hidden]="!currentResult.needleLength || currentResult.needleLength === 'NULL'">Needle Length: {{currentResult.needleLength}}<br/></span>
                        <span [hidden]="!currentResult.needleSize || currentResult.needleSize === 'NULL'">Needle Size: {{currentResult.needleSize}}<br/></span>
                      </label>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th id="col.header" *ngFor="let col of cols">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-currentResult>
                        <tr>
                            <td class="d-table-cell" *ngFor="let col of cols">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    </p-table>
                </ng-container>
                </app-infinite-scroll>
            </div>
            <ng-template #no_results>
                <div class="heading-div">
                    <h1 class="header1 f-60">{{adam_labels.search_results_module.noRstFound}}</h1>
                    <label class="sub-title">{{adam_labels.search_results_module.failureHeader1}}"{{searchQueryObj.query}}" {{adam_labels.search_results_module.failureHeader2}} <span *ngIf="predictedResult.data !=''">{{adam_labels.search_results_module.failureHeader3}}<a class="red" href="" (click) ="showPredictedResult($event)">{{predictedResult.data}}</a>?</span></label>
                </div>
                <h3 class="header3 header3-mrg">{{adam_labels.search_results_module.failureSubHeader}}</h3>
                <ul class="no-search-list">
                    <li class="f-16">{{adam_labels.search_results_module.error1}}</li>
                    <li class="f-16">{{adam_labels.search_results_module.error2}}</li>
                </ul>
            </ng-template>
        </div>
    </div>
</div>
